import { informedConsentList } from "../../types";
export const dummyInformedConsents: informedConsentList[] = [
  {
    id: "2",
    processId: "3045002",
    template: { name: "Anestesia - Epidural" },
    user: {
      fullName: "Joana Lopes Silva",
      birth: "1985-05-05",
      docId: "DOC456",
    },
    askedBy: {
      clinicalName: "Maria Fernandes",
      internalNumber: "INT001", // REMOVER
      clinicalNumber: "CLN001", // REMOVER
    },
    responsableHps: [
      {
        clinicalName: "Pedro Andrade",
        internalNumber: "INT001", // REMOVER
        clinicalNumber: "CLN001", // REMOVER
      },
    ],
    userTxHash: "",
    responsableHpTxHashes: ["HASH012"],
    createdAt: "2024-10-30T10:15:00Z", // Updated date
  },
  {
    id: "3",
    processId: "3045003",
    template: { name: "Anestesia Local" },
    user: {
      fullName: "Alice Manuela Martins",
      birth: "1992-03-03",
      docId: "DOC789",
    },
    askedBy: {
      clinicalName: "Maria Fernandes",
      internalNumber: "INT001", // REMOVER
      clinicalNumber: "CLN001", // REMOVER
    },
    responsableHps: [
      {
        clinicalName: "Pedro Andrade",
        internalNumber: "INT001", // REMOVER
        clinicalNumber: "CLN001", // REMOVER
      },
    ],
    userTxHash: "HASH345",
    responsableHpTxHashes: ["", "HASH678"],
    createdAt: "2024-11-04T09:45:00Z", // Updated date
  },
  {
    id: "1",
    processId: "3045001",
    template: { name: "Anestesia Geral" },
    user: {
      fullName: "Mário Lopes Antunes",
      birth: "1990-01-01",
      docId: "DOC123",
    },
    askedBy: {
      clinicalName: "Maria Fernandes",
      internalNumber: "INT001", // REMOVER
      clinicalNumber: "CLN001", // REMOVER
    },
    responsableHps: [
      {
        clinicalName: "Pedro Andrade",
        internalNumber: "INT001", // REMOVER
        clinicalNumber: "CLN001", // REMOVER
      },
    ],
    userTxHash: "HASH123",
    responsableHpTxHashes: ["HASH456"],
    createdAt: "2024-10-30T09:30:00Z", // Updated date
  },
  {
    id: "4",
    processId: "3045004",
    template: { name: "Sedação Consciente" },
    user: {
      fullName: "Manuel Faria Santos",
      birth: "1988-07-07",
      docId: "DOC012",
    },
    askedBy: {
      clinicalName: "Maria Fernandes",
      internalNumber: "INT001", // REMOVER
      clinicalNumber: "CLN001", // REMOVER
    },
    responsableHps: [
      {
        clinicalName: "Pedro Andrade",
        internalNumber: "INT001", // REMOVER
        clinicalNumber: "CLN001", // REMOVER
      },
    ],
    userTxHash: "HASH901",
    responsableHpTxHashes: ["HASH234"],
    createdAt: "2024-11-05T08:30:00Z", // Updated date
  },
  {
    id: "5",
    processId: "3045005",
    template: { name: "Anestesia Geral" },
    user: {
      fullName: "David Faria",
      birth: "1995-09-09",
      docId: "DOC345",
    },
    askedBy: {
      clinicalName: "Maria Fernandes",
      internalNumber: "INT001", // REMOVER
      clinicalNumber: "CLN001", // REMOVER
    },
    responsableHps: [
      {
        clinicalName: "Pedro Andrade",
        internalNumber: "INT001", // REMOVER
        clinicalNumber: "CLN001", // REMOVER
      },
    ],
    userTxHash: "HASH567",
    responsableHpTxHashes: ["HASH890"],
    createdAt: "2024-11-06T10:00:00Z", // Updated date
  },
  {
    id: "6",
    processId: "3045006",
    template: { name: "Anestesia Local" },
    user: {
      fullName: "Carlos Silva",
      birth: "1993-11-11",
      docId: "DOC678",
    },
    askedBy: {
      clinicalName: "Maria Fernandes",
      internalNumber: "INT001", // REMOVER
      clinicalNumber: "CLN001", // REMOVER
    },
    responsableHps: [
      {
        clinicalName: "Pedro Andrade",
        internalNumber: "INT001", // REMOVER
        clinicalNumber: "CLN001", // REMOVER
      },
    ],
    userTxHash: "HASH890",
    responsableHpTxHashes: ["HASH123"],
    createdAt: "2024-11-07T09:15:00Z", // Updated date
  },
  {
    id: "7",
    processId: "3045007",
    template: { name: "Anestesia Local" },
    user: {
      fullName: "Sofia Martins",
      birth: "1991-02-02",
      docId: "DOC234",
    },
    askedBy: {
      clinicalName: "Fátima Sousa",
      internalNumber: "INT009", // REMOVER
      clinicalNumber: "CLN009", // REMOVER
    },
    responsableHps: [
      {
        clinicalName: "Pedro Andrade",
        internalNumber: "INT001", // REMOVER
        clinicalNumber: "CLN001", // REMOVER
      },
    ],
    userTxHash: "HASH456",
    responsableHpTxHashes: ["HASH789"],
    createdAt: "2024-11-01T10:30:00Z", // Updated date
  },
  {
    id: "8",
    processId: "3045008",
    template: { name: "Sedação Consciente" },
    user: {
      fullName: "Pedro Oliveira",
      birth: "1989-08-08",
      docId: "DOC345",
    },
    askedBy: {
      clinicalName: "Maria Fernandes",
      internalNumber: "INT001", // REMOVER
      clinicalNumber: "CLN001", // REMOVER
    },
    responsableHps: [
      {
        clinicalName: "Pedro Andrade",
        internalNumber: "INT001", // REMOVER
        clinicalNumber: "CLN001", // REMOVER
      },
    ],
    userTxHash: "HASH234",
    responsableHpTxHashes: ["HASH567"],
    createdAt: "2024-11-02T09:00:00Z", // Updated date
  },
  {
    id: "9",
    processId: "3045009",
    template: { name: "Anestesia Geral" },
    user: {
      fullName: "Luís Pereira",
      birth: "1994-04-04",
      docId: "DOC456",
    },
    askedBy: {
      clinicalName: "Maria Fernandes",
      internalNumber: "INT001", // REMOVER
      clinicalNumber: "CLN001", // REMOVER
    },
    responsableHps: [
      {
        clinicalName: "Pedro Andrade",
        internalNumber: "INT001", // REMOVER
        clinicalNumber: "CLN001", // REMOVER
      },
    ],
    userTxHash: "HASH678",
    responsableHpTxHashes: ["HASH012"],
    createdAt: "2024-11-03T08:45:00Z", // Updated date
  },
  {
    id: "10",
    processId: "3045010",
    template: { name: "Anestesia - Epidural" },
    user: {
      fullName: "Rita Ferreira",
      birth: "1996-10-10",
      docId: "DOC789",
    },
    askedBy: {
      clinicalName: "Fátima Sousa",
      internalNumber: "INT009", // REMOVER
      clinicalNumber: "CLN009", // REMOVER
    },
    responsableHps: [
      {
        clinicalName: "Pedro Andrade",
        internalNumber: "INT001", // REMOVER
        clinicalNumber: "CLN001", // REMOVER
      },
    ],
    userTxHash: "HASH345",
    responsableHpTxHashes: ["HASH678"],
    createdAt: "2024-11-04T09:30:00Z", // Updated date
  },
  {
    id: "11",
    processId: "3045011",
    template: { name: "Sedação Consciente" },
    user: {
      fullName: "André Costa",
      birth: "1987-12-12",
      docId: "DOC012",
    },
    askedBy: {
      clinicalName: "Fátima Sousa",
      internalNumber: "INT009", // REMOVER
      clinicalNumber: "CLN009", // REMOVER
    },
    responsableHps: [
      {
        clinicalName: "Pedro Andrade",
        internalNumber: "INT001", // REMOVER
        clinicalNumber: "CLN001", // REMOVER
      },
    ],
    userTxHash: "HASH901",
    responsableHpTxHashes: ["HASH234"],
    createdAt: "2024-11-01T10:00:00Z", // Updated date
  },
  {
    id: "12",
    processId: "3045012",
    template: { name: "Anestesia Local" },
    user: {
      fullName: "Inês Ribeiro",
      birth: "1992-01-01",
      docId: "DOC345",
    },
    askedBy: {
      clinicalName: "Maria Fernandes",
      internalNumber: "INT001", // REMOVER
      clinicalNumber: "CLN001", // REMOVER
    },
    responsableHps: [
      {
        clinicalName: "Pedro Andrade",
        internalNumber: "INT001", // REMOVER
        clinicalNumber: "CLN001", // REMOVER
      },
    ],
    userTxHash: "HASH567",
    responsableHpTxHashes: ["HASH890"],
    createdAt: "2024-11-07T09:00:00Z", // Updated date
  },
];
