// React
import React, { useState } from "react";

// Components
import { Input } from "../../../components/ui/input";
import { Button } from "../../../components/ui/button";
// Utility
import showToast from "../../../components/Toast";
import {
  CardContent,
  CardDescription,
  CardTitle,
} from "../../../components/ui/card";
import Loading from "../../../components/loading";

const PasswordInput = ({
  setDialogIsOpen,
}: {
  setDialogIsOpen: (value: boolean) => void;
}) => {
  // State
  const [password, setPassword] = useState<string>("");
  const [isConfirming, setIsConfirming] = useState<boolean>(false);

  const handleButtonClick = () => {
    setIsConfirming(true);
    const timer = setTimeout(() => {
      setDialogIsOpen(false);
      showToast("Consentimentos assinados com sucesso", "success", 3000);
    }, 3000);
    return () => clearTimeout(timer);
  };

  return (
    <div className="flex flex-col h-full">
      <CardContent className="grid gap-2">
        <CardTitle>Insira o Código SMS</CardTitle>
        <CardDescription>
          Insira o código enviado por SMS para o telemóvel do Utente.
        </CardDescription>
      </CardContent>
      <div className="flex flex-col items-center justify-center h-[20vh]">
        <div className="flex flex-col items-center gap-2">
          {isConfirming ? (
            <div className="py-10 flex justify-center">
              <Loading full={false} />
            </div>
          ) : (
            <Input
              type="text"
              placeholder="Código OTP"
              onChange={(e) => setPassword(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleButtonClick(); // Call handleButtonClick on Enter key press
                }
              }}
            />
          )}
          {!isConfirming && (
            <Button className="w-full" onClick={handleButtonClick}>
              Confirmar
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default PasswordInput;
