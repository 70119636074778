import { Chain } from "thirdweb";
import { Account } from "thirdweb/wallets";

export class Blockchain {
  eoaAccount: Account = {} as Account;
  smartAccount: Account = {} as Account;

  constructor(client: any, chain: Chain) {}

  updateAccounts(eoaAccount: Account, smartAccount: Account) {
    this.eoaAccount = eoaAccount;
    this.smartAccount = smartAccount;
  }
}
