// React
import React, { memo, useContext } from "react";

// Hooks
import { useLocation, useNavigate } from "react-router-dom";

// Components
import { HpContext } from "../../services/hp";

// Icons / Styles
import { HiArrowLongLeft } from "react-icons/hi2";

interface ErrorPageProps {
  mode?: "NOT_FOUND" | "NOT_VERIFIED" | "TIMEDOUT";
}

function ErrorPage({ mode }: ErrorPageProps) {
  const navigate = useNavigate();
  const hpContext = useContext(HpContext);

  // For TIMEOUT
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const queryParamsMode = queryParams.get("mode");
  const errorMode = queryParamsMode ? queryParamsMode : mode;

  return (
    <section className="bg-white dark:bg-gray-900 ">
      {errorMode === "NOT_FOUND" && (
        <div className="container flex items-center min-h-screen px-6 py-12 mx-auto">
          <div className="pl-4">
            <p className="text-md font-medium text-teal-700 dark:text-blue-400">
              Erro 404
            </p>
            <h1 className="mt-3 text-5xl font-semibold text-gray-800 dark:text-white md:text-4xl">
              Página não encontrada
            </h1>
            <p className="mt-4 text-gray-500 dark:text-gray-400 text-lg">
              Pedimos desculpa, mas a página que procura não foi encontrada.
            </p>
            <div className="flex items-center mt-6 gap-x-3">
              <button
                onClick={() => {
                  if (hpContext?.hp) navigate("/people");
                  else navigate("/");
                }}
                className="flex items-center justify-center w-1/2 px-5 py-2 text-sm text-gray-700 transition-colors duration-200 bg-white border rounded-lg gap-x-2 sm:w-auto dark:hover:bg-gray-800 dark:bg-gray-900 hover:bg-gray-100 dark:text-gray-200 dark:border-gray-700"
              >
                <HiArrowLongLeft className="w-6 h-6" />
                <span>Voltar à página inicial</span>
              </button>
              <button
                className="w-1/2 px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-teal-600 rounded-lg shrink-0 sm:w-auto hover:bg-teal-700 dark:hover:bg-blue-500 dark:bg-blue-600"
                onClick={() => navigate("/settings?tab=4")}
              >
                Ajuda
              </button>
            </div>
          </div>
        </div>
      )}
      {errorMode === "TIMEOUT" && (
        <div className="container flex items-center min-h-screen px-6 py-12 mx-auto">
          <div className="pl-4">
            <p className="text-md font-medium text-teal-700 dark:text-blue-400">
              Erro de conexão
            </p>
            <h1 className="mt-3 text-5xl font-semibold text-gray-800 dark:text-white md:text-4xl">
              O tempo de ligação expirou.
            </h1>
            <p className="mt-4 text-gray-500 dark:text-gray-400 text-lg">
              Pedimos desculpa, ocorreu um erro de conexão. Por favor, tente
              novamente.
            </p>
            <div className="flex items-center mt-6 gap-x-3">
              <button
                onClick={() => {
                  if (hpContext?.hp) navigate("/people");
                  else navigate("/");
                }}
                className="flex items-center justify-center w-1/2 px-5 py-2 text-sm text-gray-700 transition-colors duration-200 bg-white border rounded-lg gap-x-2 sm:w-auto dark:hover:bg-gray-800 dark:bg-gray-900 hover:bg-gray-100 dark:text-gray-200 dark:border-gray-700"
              >
                <HiArrowLongLeft className="w-6 h-6" />
                <span>Voltar à página inicial</span>
              </button>
              <button
                className="w-1/2 px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-teal-600 rounded-lg shrink-0 sm:w-auto hover:bg-teal-700 dark:hover:bg-blue-500 dark:bg-blue-600"
                onClick={() => navigate("/settings?tab=4")}
              >
                Ajuda
              </button>
            </div>
          </div>
        </div>
      )}
      {hpContext?.hp && (
        <span
          onClick={() => hpContext.logout(true)}
          className="fixed top-0 right-0 m-4 flex items-center justify-center px-5 py-2 text-sm text-gray-700 duration-300 bg-white rounded-lg gap-x-2 sm:w-auto dark:hover:bg-gray-800 dark:bg-gray-900 hover:bg-gray-100 dark:text-gray-200 dark:border-gray-700 hover:cursor-pointer"
        >
          <HiArrowLongLeft className="w-6 h-6" />
          <span>Terminar sessão</span>
        </span>
      )}
    </section>
  );
}
export default memo(ErrorPage); // To check performance increase
