// React
import React, { memo, useEffect, useState } from "react";

// Hooks
import { useNavigate } from "react-router-dom";

// Components
import {
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../components/ui/card";
import { Label } from "../../components/ui/label";
import { Input } from "../../components/ui/input";
import { Button } from "../../components/ui/button";
import { transformDateString } from "../../utils/dateTimeUtils";

const SearchPatient = () => {
  const navigate = useNavigate();

  const [docId, setUserCC] = useState("");
  const [birth, setBirth] = useState("");

  // Handle action with Enter
  useEffect(() => {
    const handleEnter = (e: KeyboardEvent) => {
      if (e.key === "Enter") {
        handleAction();
      }
    };

    window.addEventListener("keydown", handleEnter);
    return () => {
      window.removeEventListener("keydown", handleEnter);
    };
  }, [docId, birth]);

  const handleAction = () => {
    navigate(`/people/${docId}/${birth}`);
  };

  return (
    <div className="flex flex-col h-full">
      <CardHeader>
        <CardTitle>Procurar Utente</CardTitle>
        <CardDescription>Insira os dados do utente</CardDescription>
      </CardHeader>
      <CardContent className="grid gap-2">
        <div>
          <div className="grid gap-2">
            <Label htmlFor="subject">Utente</Label>
            <Input
              id="subject"
              placeholder="Nº do Cartão de Cidadão"
              value={docId}
              onChange={(e) => setUserCC(e.target.value)}
              disabled={false}
              name="cc"
            />
          </div>

          <div className="grid gap-2 mt-8">
            <Label htmlFor="subject">Data de nascimento</Label>
            <Input
              id="subject"
              placeholder="DD/MM/AAAA"
              value={birth}
              onChange={(e) => setBirth(e.target.value)}
              disabled={false}
              name="birth"
            />
          </div>
        </div>
      </CardContent>
      <CardFooter className="justify-end space-x-2 mt-auto">
        <Button
          onClick={handleAction}
          disabled={docId.length !== 8 || !transformDateString(birth)}
        >
          Procurar Utente
        </Button>
      </CardFooter>
    </div>
  );
};

export default memo(SearchPatient);
