import { Document, Page, Text, StyleSheet, Image } from "@react-pdf/renderer";
import { patientInformedConsentDownload } from "../../../types";

// Register Open Sans font

// Define styles
const styles = StyleSheet.create({
  page: { padding: 60, lineHeight: 1.4, textAlign: "justify" },
  title: {
    fontSize: 11,
    fontWeight: "bold",
    marginBottom: 10,
    marginTop: 20,
  },
  text: { fontSize: 10 }, //
  boldText: {
    fontSize: 10,
    fontWeight: "bold",
  },
});

// Updated PDF content component
const IdentitiesTemplatePDF = ({
  internalConsent,
}: {
  internalConsent: patientInformedConsentDownload;
}) => {
  if (!internalConsent) {
    console.error("No internal consent data provided");
    return null;
  }

  return (
    <Document>
      <Page style={styles.page}>
        {/* Centered Logo */}
        <Text
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 30,
          }}
        >
          <Image
            src="/uls.png"
            style={{
              width: 160,
              height: 35,
              marginRight: 10,
            }}
          />
          <Image
            src="/humanos-logo-black.png"
            style={{
              marginTop: 20,
              width: 35,
              height: 35,
              alignSelf: "flex-end", // Aligns the image to the right
            }}
          />
        </Text>

        <Text style={[styles.title]}>Detalhes do Procedimento</Text>
        <Text style={[styles.text, { marginBottom: 20 }]}>
          {`• Procedimento: ${internalConsent?.template?.name || "N/A"}`}
          {"\n"}
          {`• Local: Hospital de Santa Maria, Lisboa`}
          {"\n"}
          {`• Data e Hora da Assinatura: ${
            internalConsent?.createdAt || "N/A"
          }`}
          {"\n"}
          {`• Localização IP: 192.168.1.1 (opcional conforme RGPD)`}
          {"\n"}
          {`• Código Identificador Único do Documento: ${
            internalConsent?.template?.hash || "N/A"
          }`}
          {"\n"}
          {`• Validade do Consentimento: Até à conclusão do procedimento ou até`}
          {"\n"}
          {`revogação, conforme aplicável.`}
        </Text>

        <Text style={[styles.text]}>
          <Text style={styles.boldText}>Motivo do Consentimento:</Text>{" "}
          Autorização para a realização do procedimento de anestesia geral
          necessário para a intervenção cirúrgica proposta, conforme informado.
          {"\n"}
          {"\n"}
        </Text>

        <Text style={[styles.text]}>
          <Text style={styles.boldText}>Direito de Revogação:</Text> O utente
          tem o direito de revogar este consentimento a qualquer momento antes
          do início do procedimento. Para tal, o utente deve notificar o
          hospital.{"\n"}
          {"\n"}
        </Text>

        <Text style={[styles.text]}>
          <Text style={styles.boldText}>Assinatura Digital:</Text> O
          consentimento foi assinado digitalmente através da plataforma Humanos,
          utilizando autenticação via confirmação digital do Profissional da
          identidade do Utente, leitura digital do Cartão de Cidadão do Utente e
          código OTP recebido pelo Utente via SMS, garantindo a integridade e
          autenticidade do documento.{"\n"}
          {"\n"}
        </Text>

        <Text style={[styles.title]}>Identificação dos Signatários:</Text>

        <Text style={[styles.text, { marginBottom: 20 }]}>
          <Text style={styles.boldText}>Profissional de Saúde:</Text>
          {"\n"}
          {`• Nome Clínico: ${internalConsent?.askedBy?.clinicalName || "N/A"}`}
          {"\n"}
          {`• Especialidade: ${"Anestesista" || "N/A"}`}
          {"\n"}
          {`• Número de Cédula Profissional: ${
            internalConsent?.askedBy?.clinicalNumber || "N/A"
          }`}
          {"\n"}
          {`• Instituição: ${
            internalConsent?.askedBy?.healthOrganization?.name || "N/A"
          }`}
          {"\n"}
          {`• ID da Wallet Blockchain: ${
            internalConsent?.askedBy?.hpWallet?.wallet || "N/A"
          }`}
          {"\n"}
          {"\n"}
          <Text style={styles.boldText}>Utente:</Text>
          {"\n"}
          {`• Nome: ${internalConsent?.user?.identities[0]?.fullName || "N/A"}`}
          {"\n"}
          {`• Número do Cartão de Cidadão: ${
            internalConsent?.user?.identities[0]?.docId || "N/A"
          }`}
          {"\n"}
          {`• Wallet Blockchain: ${internalConsent?.user?.wallet || "N/A"}`}
          {"\n"}
        </Text>

        <Text style={[styles.text]}>
          <Text style={styles.boldText}>Método de Autenticação:</Text>
          {"\n"}
          {`• Cartão de Cidadão (CC): Lido em leitor de cartões às 10h29 AM, 21 de Outubro de 2024`}
          {"\n"}
          {`• Código OTP Recebido por SMS: ${"874356" || "N/A"}`}
          {"\n"}
          {`• Hash ID do Consentimento: ${
            "0x9a771da05ac041ba523212d8c3448c954f0294b98679a0cf519b2713a84afbab" ||
            "N/A"
          }`}
          {"\n"}
          {`• Link para Verificação: ${
            "https://basescan.org/tx/0x9a771da05ac041ba523212d8c3448c954f0294b98679a0cf519b2713a84afbab" ||
            "N/A"
          }`}
          {"\n"}
          {`• Nome do Responsável pela Emissão do Consentimento: ${
            internalConsent?.askedBy?.clinicalName || "N/A"
          }`}
          {"\n"}
          {`• Número de Cédula Profissional do Responsável pela Emissão do Consentimento: ${
            internalConsent?.askedBy?.clinicalNumber || "N/A"
          }`}
        </Text>
      </Page>
    </Document>
  );
};

// Updated PDF content component
const RGPDTemplatePDF = ({
  internalConsent,
}: {
  internalConsent: patientInformedConsentDownload;
}) => {
  if (!internalConsent) {
    console.error("No internal consent data provided");
    return null;
  }

  return (
    <Document>
      {/* New Page for the last three paragraphs */}
      <Page style={styles.page}>
        <Text style={[styles.text, { marginBottom: 20 }]}>
          Conformidade com o RGPD: Este consentimento foi recolhido e armazenado
          em conformidade com o Regulamento Geral sobre a Proteção de Dados
          (RGPD), assegurando a privacidade e proteção de dados do utente e a
          auditabilidade do processo.
        </Text>

        <Text style={[styles.text, { marginBottom: 20 }]}>
          Declaração de Privacidade e Proteção de Dados: Este documento
          certifica que o utente deu consentimento informado e explícito para o
          procedimento mencionado. Todas as interações relacionadas com este
          consentimento, incluindo a assinatura, foram registradas de forma
          segura e imutável na blockchain, garantindo a transparência e
          rastreabilidade em benefício de ambas as partes.
        </Text>

        <Text style={styles.boldText}>
          As partes convencionam que o presente documento tem força probatória
          de documento particular assinado nos termos conjugados dos art.º 376.º
          do Código Civil e n.º 9 do art.º 3.º do Decreto-Lei n.º 12/2021.
        </Text>
      </Page>
    </Document>
  );
};

export { IdentitiesTemplatePDF, RGPDTemplatePDF };
