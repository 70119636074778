// React
import React, { memo, useContext, useState } from "react";

// Components
import {
  CardContent,
  CardDescription,
  CardFooter,
  CardTitle,
} from "../../../components/ui/card";
import { Button } from "../../../components/ui/button";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "../../../components/ui/avatar";
import { Checkbox } from "../../../components/ui/checkbox";

// Types
import { HpContext } from "../../../services/hp";
import { patient } from "../../../types";

// Add TypeScript types for props
interface Card1VerifyIdentityProps {
  patient: patient | null;
  nextStep: () => void;
}

const Card1VerifyIdentity = ({
  patient,
  nextStep,
}: Card1VerifyIdentityProps) => {
  // Affiliation context
  const hpContext = useContext(HpContext);
  const [hasVerified, setHasVerified] = useState(false);

  const patientRow = () => {
    if (!patient) return null; // Verify if selectedUser exists
    return (
      <div className="grid grid-cols-[auto,1fr,auto] gap-4 items-center relative p-2 border rounded-md mt-5">
        <Avatar className="h-12 w-12 flex-shrink-0">
          <AvatarImage
            src={
              patient.photo
                ? `data:image/png;base64,${patient.photo}`
                : // eslint-disable-next-line
                  require("../../../components/images/profile.jpg")
            }
            alt="@shadcn"
          />
          <AvatarFallback>RG</AvatarFallback>
        </Avatar>
        <div className="flex flex-col text-sm min-w-0">
          <span className="font-semibold break-words">{patient.fullName}</span>
          <span className="break-words">{patient.birth}</span>
        </div>
      </div>
    );
  };

  const verifyIdentity = () => {
    return (
      <div className="grid grid-cols-[auto,1fr,auto] gap-4 items-center relative p-2 border rounded-md">
        <CardDescription className="flex items-center">
          <div className="w-[95%] text-justify">
            Eu,{" "}
            <span className="font-bold text-black">
              {hpContext?.hp?.clinicalName}
            </span>
            , Profissional de{" "}
            <span className="font-bold text-black">
              {hpContext?.hp?.healthOrganizationName}
            </span>
            , declaro que estou a interagir com o Utente{" "}
            <span className="font-bold text-black">{patient?.fullName}</span>,
            portador do Cartão de Cidadão com número{" "}
            <span className="font-bold text-black">{patient?.docId}</span> acima
            apresentado.
          </div>
          <div className="flex justify-center w-[5%]">
            <Checkbox
              checked={hasVerified}
              onCheckedChange={toggleVerified}
              className="ml-10"
              id="terms"
            />
          </div>
        </CardDescription>
      </div>
    );
  };

  const toggleVerified = () => {
    setHasVerified(!hasVerified);
  };

  return (
    <div className="flex flex-col h-full">
      <CardContent className="grid gap-2">
        <CardTitle>Confirmação de Identidade</CardTitle>
        <CardDescription>Confirmação da identidade do Utente.</CardDescription>
        {patientRow()}
        {verifyIdentity()}
      </CardContent>
      <CardFooter className="justify-end space-x-2 mt-auto">
        <Button disabled={!hasVerified} onClick={() => nextStep()} className="">
          Verificar
        </Button>
      </CardFooter>
    </div>
  );
};

export default memo(Card1VerifyIdentity);
