import { TOOLTIP_LENGTH } from "../environment/variables";

export function isEmail(email: string): boolean {
  if (!email) return false;
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

export function emptyOrStringInLimits(
  string: string | undefined | null,
  max: number,
  min?: number
): boolean {
  if (!string) return true; // undefined, empty string, ...
  if (min && string.length < min) return false;
  if (max && string.length > max) return false;
  return true;
}

export function stringInLimits(
  string: string,
  max: number,
  min?: number
): boolean {
  if (!string) return false;
  if (min && string.length < min) return false;
  if (string.length > max) return false;
  return true;
}

export function subString(fullString: string) {
  return fullString && fullString.length > TOOLTIP_LENGTH
    ? `${fullString.slice(0, TOOLTIP_LENGTH)}...`
    : fullString;
}

export function firstAndLastName(fullName: string): string {
  const names = fullName.split(" ");
  return `${names[0]} ${names[names.length - 1]}`;
}
