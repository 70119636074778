// React
import React from "react";

// Components
import { Card, CardContent, CardHeader } from "../../components/ui/card";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../components/ui/accordion";
import Loading from "../../components/loading";

// Types

// Hooks
import { calculateAge } from "../../utils/dateTimeUtils";
import { patient } from "../../types";

interface PatientCardInterface {
  patient: patient | null;
  isLoading: boolean;
}

// They change all at the same time, so not a lot of re-renders
function PatientCard({ patient, isLoading }: PatientCardInterface) {
  return (
    <div>
      <Card className="border bg-white">
        {isLoading ? (
          <div className="py-48">
            <Loading full={false} />
          </div>
        ) : (
          <div>
            <CardHeader className="flex flex-row items-start justify-between space-y-0 pb-0 pt-4">
              <div className="flex items-center">
                <div className="w-24 h-24 rounded-full overflow-hidden mr-3 flex-shrink-0">
                  <img
                    src={
                      patient?.photo
                        ? `data:image/png;base64,${patient.photo}`
                        : // eslint-disable-next-line
                          require("../../components/images/profile.jpg")
                    }
                    alt="Profile"
                    className="w-24 h-24 object-cover"
                  />
                </div>
                <CardContent className="p-0">
                  <div className="text-xl font-bold">
                    {patient?.fullName || "Não Existente"}
                  </div>
                  <p className="text-sm text-muted-foreground">
                    {patient?.birth || "DD-MM-AAAA"}
                  </p>
                </CardContent>
              </div>
            </CardHeader>
            <Accordion
              type="single"
              collapsible
              className="w-full px-10 mt-10"
              defaultValue="item-1"
            >
              <AccordionItem value="item-1">
                <AccordionTrigger className="text-sm text-base lg:text-lg">
                  Informação
                </AccordionTrigger>
                <AccordionContent>
                  <div className="info-list mt-4">
                    <div className="info-item flex justify-between py-2">
                      <span className="label font-bold mr-2">
                        Data de nascimento
                      </span>
                      <span className="value text-right flex-grow">
                        {patient?.birth || "Não definido"}
                      </span>
                    </div>
                    <div className="info-item flex justify-between py-2">
                      <span className="label font-bold mr-2">Idade</span>
                      <span className="value text-right flex-grow">
                        {patient?.birth
                          ? calculateAge(patient?.birth)
                          : "Não definido"}
                      </span>
                    </div>
                    <div className="info-item flex justify-between py-2">
                      <span className="label font-bold mr-2">Sexo</span>
                      <span className="value text-right flex-grow">
                        {patient?.gender
                          ? patient?.gender === "M"
                            ? "Masculino"
                            : "Feminino"
                          : "Não definido"}
                      </span>
                    </div>
                    <div className="info-item flex justify-between py-2">
                      <span className="label font-bold mr-2">
                        Cartão de Cidadão
                      </span>
                      <span className="value text-right flex-grow">
                        {patient?.docId || "Não definido"}
                      </span>
                    </div>
                    <div className="info-item flex justify-between py-2">
                      <span className="label font-bold mr-2">
                        Número de Utente
                      </span>
                      <span className="value text-right flex-grow">
                        {patient?.healthNumber || "Não definido"}
                      </span>
                    </div>
                  </div>
                </AccordionContent>
              </AccordionItem>
              <AccordionItem value="item-2" className="border-b-0">
                <AccordionTrigger className="text-sm text-base lg:text-lg">
                  Contactos
                </AccordionTrigger>
                <AccordionContent>
                  <div className="info-list mt-4">
                    <div className="info-item flex justify-between py-2">
                      <span className="label font-bold mr-2">Email</span>
                      <span className="value text-right flex-grow">
                        {patient?.user?.email || "Não definido"}
                      </span>
                    </div>
                    <div className="info-item flex justify-between py-2">
                      <span className="label font-bold mr-2">Telemóvel</span>
                      <span className="value text-right flex-grow">
                        {patient?.user?.phoneLogin || "Não definido"}
                      </span>
                    </div>
                  </div>
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          </div>
        )}
      </Card>
    </div>
  );
}

export default PatientCard;
