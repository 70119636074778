// React
import React, {
  useState,
  useEffect,
  useContext,
  useMemo,
  useCallback,
} from "react";
import { useNavigate } from "react-router-dom";

// External
import { keccak256 } from "@ethersproject/keccak256";
import { toUtf8Bytes } from "@ethersproject/strings";

// Components
import { Input } from "../../components/ui/input";
import { Button } from "../../components/ui/button";
import { Separator } from "../../components/ui/separator";
import showToast from "../../components/Toast";

// Shadcn
import { motion } from "framer-motion";

// Types
import { HpContext } from "../../services/hp";

// Thirdweb
import { createThirdwebClient, defineChain } from "thirdweb";
import { inAppWallet, smartWallet } from "thirdweb/wallets";
import { baseSepolia } from "thirdweb/chains";

// Internal
import { CLIENT_ID, FACTORY_ADDRESS } from "../../environment/variables";
import { isEmail } from "../../utils/stringUtils";

// Firebase imports
import {
  auth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "../../services/firebase";

import axios from "axios";
import { login } from "../../auth/auth";

const Login = () => {
  // Context
  const hpContext = useContext(HpContext);
  const [user, setUser] = useState<any>();

  // Navigation
  const navigate = useNavigate();

  // Thirdweb
  const client = useMemo(
    () => createThirdwebClient({ clientId: CLIENT_ID }),
    []
  );
  const chain = defineChain(baseSepolia); // Better without useMemo

  // Login email
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");

  // Toggle
  const [isLogin, setIsLogin] = useState<boolean>(true); // Claim or Login
  const [loggingIn, setLoggingIn] = useState<boolean>(false);

  // Firebase Auth
  useEffect(() => {
    const subscriber = auth.onAuthStateChanged(onAuthStateChanged);
    return () => subscriber();
  }, [onAuthStateChanged]);

  function onAuthStateChanged(user: any) {
    setUser(user);
  }

  const _allowLogin = async (email: string): Promise<boolean> => {
    try {
      const { data } = await axios.get<[boolean, boolean]>(
        `auth/allowLogin/${email}`
      );
      if (data[0] === false) {
        showToast("Conta inválida.", "error", 3000);
        return false;
      }
      if (isLogin && data[1] === false) {
        showToast("Primeiro precisa fazer claim de conta.", "error", 3000); // Dialog
        return false;
      }
      if (!isLogin && data[1] === true) {
        showToast("O claim desta conta já foi feito.", "error", 3000);
        return false;
      }
      return true;
    } catch (error) {
      console.error("Error checking allowLogin:", error);
      return false;
    }
  };

  // CALLBACK ON DEBOUNCE (LOGIN)

  const instantiateAccount = useCallback(async () => {
    if (!loggingIn) setLoggingIn(true);
    try {
      // Step 1: Get JWT from Firebase
      if (!user) return;
      const jwt = await user.getIdToken();
      console.log("jwt", jwt);

      // Step 2:Connect to eoaAccount
      const userInAppWallet = inAppWallet();
      const eoaAccount = await userInAppWallet.connect({
        client,
        strategy: "jwt",
        jwt: jwt,
        encryptionKey: "your-encryption-key",
      });
      console.log("eoaAccount", eoaAccount);

      // Step 3: Connect to smartAccount
      const smart = smartWallet({
        factoryAddress: FACTORY_ADDRESS,
        gasless: true,
        chain: chain,
      });
      console.log("smart", smart);

      // Step 4: Connect to smartAccount
      const smartAccount = await smart.connect({
        client,
        personalAccount: eoaAccount,
      });
      console.log("smartAccount", smartAccount);

      // Step 4: Get JWT
      const hp = await login(eoaAccount, true);
      if (!hp) {
        showToast("Erro ao fazer login.", "error", 3000);
        return;
      }
      // Step 5: Save loginHash cookie
      const loginHash = keccak256(
        toUtf8Bytes(
          [hp.username, hp.institutionalEmail, password, "HUMANOS"].join("")
        )
      );
      // Step 6: Save on hpContext and navigate
      hpContext?.manualLogin(
        userInAppWallet,
        eoaAccount,
        smartAccount,
        hp,
        loginHash
      );
      navigate("/people");
    } catch (error) {
      console.error(error);
    } finally {
      setLoggingIn(false);
    }
  }, [loggingIn, client, chain, hpContext]);

  const handleLogin = async (email: string, password: string) => {
    if (!(await _allowLogin(email))) return;
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      if (userCredential.user) {
        instantiateAccount();
      }
      console.log("User logged in:", userCredential.user);
    } catch (error) {
      console.error("Error logging in:", error);
    }
  };

  const handleSignUp = async (email: string, password: string) => {
    if (!(await _allowLogin(email))) return;
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      console.log("userCredential", userCredential);
      if (!userCredential.user) throw new Error("Erro ao criar conta");
      await instantiateAccount();
      showToast("Conta criada com sucesso.", "success", 3000);
    } catch (error: any) {
      if (error && error.code && error.code === "auth/email-already-in-use") {
        await instantiateAccount();
        // Created account in firebase but didnt reach backend
        showToast(
          "Conta criada com sucesso, pode fazer login.",
          "success",
          3000
        );
        setIsLogin(true);
      }
    }
  };

  // Extracted path data from your SVG
  const paths = [
    "M81.53,570.8c-27.06-51.1-41.36-108.84-41.36-166.99c0-86.99,31.63-170.82,89.05-236.04 C186.01,103.28,263.89,61.4,348.57,49.8c2.59,166.55,140.08,301.94,306.74,301.94c28.23,0,56.23-3.86,83.25-11.46 C734.99,429,673.08,486.2,543.79,520.24C424.56,551.63,257.95,560.95,81.53,570.8z",
    "M397.53,761.17c-62.18,0-123.41-16.21-177.07-46.89c-51.81-29.62-95.75-72.09-127.14-122.89 331.3-18.53,617.57-34.67,660.88-209.8c0.46,7.38,0.69,14.84,0.69,22.22c0,48.24-9.45,95.04-28.08,139.1 c-18,42.55-43.77,80.77-76.59,113.59c-32.82,32.82-71.04,58.59-113.59,76.59C492.58,751.72,445.78,761.17,397.53,761.17z",
  ];

  const icon = {
    hidden: {
      pathLength: 0,
      fill: "rgba(255, 255, 255, 0)",
    },
    visible: {
      pathLength: 1,
      fill: "rgba(255, 255, 255, 1)",
    },
  };

  return (
    <>
      <div className="flex bg-black h-screen w-screen items-center justify-center">
        <div className="flex flex-col items-center justify-center">
          <div className="flex pb-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 795.06 810.89"
              width="60"
              height="60"
            >
              {paths.map((d, index) => (
                <motion.path
                  key={index}
                  d={d}
                  variants={icon}
                  initial="hidden"
                  animate="visible"
                  transition={{
                    default: { duration: 1.5, ease: "circInOut" },
                    fill: { duration: 1.7, ease: [1, 0, 0.8, 1] },
                  }}
                  stroke="white"
                  strokeWidth="4"
                />
              ))}
            </svg>
          </div>
          <h1 className="text-white text-3xl tracking-wider mt-2 mb-6 sm:mx-2">
            Humanos
          </h1>
          <Input
            type="email"
            placeholder="email@example.com"
            onChange={(e) => setEmail(e.target.value)}
            className="bg-black text-white border-neutral-800 placeholder:text-neutral-700 mb-2"
            disabled={loggingIn}
          />
          <Input
            type="password"
            placeholder="password"
            onChange={(e) => setPassword(e.target.value)}
            className="bg-black text-white border-neutral-800 placeholder:text-neutral-700 mb-2"
            disabled={loggingIn}
          />
          {!isLogin && (
            <Input
              type="password"
              placeholder="confirm password"
              onChange={(e) => setConfirmPassword(e.target.value)}
              className="bg-black text-white border-neutral-800 placeholder:text-neutral-700 mb-2"
              disabled={loggingIn}
            />
          )}

          <Button
            variant="outline"
            className="w-full"
            onClick={() =>
              isLogin
                ? handleLogin(email, password)
                : handleSignUp(email, confirmPassword)
            }
            disabled={
              loggingIn || !isEmail(email) || !hpContext?.allowManualLogin
            }
          >
            {isLogin ? "Iniciar Sessão" : "Criar Conta"}
          </Button>

          <div className="flex items-center justify-center space-x-2 mt-6">
            <Separator className="bg-neutral-700 w-24" />
            <p className="text-neutral-700 text-xs font-light uppercase text-nowrap">
              Termos e Condições
            </p>
            <Separator className="w-24 bg-neutral-700" />
          </div>
          <p className="text-muted-foreground mt-4 text-xs font-light break-all text-center max-w-72 text-neutral-700">
            Ao clicar em continuar, você concorda com os nossos{" "}
            <span className="underline hover:cursor-pointer hover:text-neutral-200 transition-all text-neutral-700 ">
              Termos de Serviço
            </span>{" "}
            e{" "}
            <span className="underline hover:cursor-pointer hover:text-neutral-200 transition-all">
              Política de Privacidade
            </span>
          </p>
        </div>
      </div>
      <button
        className="absolute top-10 right-10 px-4 py-2 rounded-md border-2 border-neutral-800 hover:bg-neutral-900 transition-all text-gray-200 text-sm"
        onClick={() => setIsLogin(!isLogin)}
      >
        {isLogin ? "Claim de Conta" : "Iniciar Sessão"}
      </button>
    </>
  );
};

export default Login;
