"use server";

// Thirdweb
import { createAuth, LoginPayload, signLoginPayload } from "thirdweb/auth";
import { Account } from "thirdweb/wallets";

// External
import axios from "axios";

// Internal
import { hpWhoAmI } from "../types";
import { COOKIE_NAME, FRONTEND_DOMAIN } from "../environment/variables";
import Cookies from "js-cookie";

const thirdwebAuth = createAuth({
  domain: FRONTEND_DOMAIN,
});

// Signs payload received from backend
export async function login(
  account: Account,
  manualLogin: boolean
): Promise<hpWhoAmI | null> {
  try {
    // Paload = sucess ;
    // else true = user exists but is blocked
    // false --> user exist but loggedOut
    const { data: payload } = await axios.get<LoginPayload | null>(
      `auth/payload/${account.address}/${manualLogin}`
    );
    console.log("Payload", payload);

    // If payload fails, return
    if (!payload) return null;

    // Step 2: CLIENT - Signs the payload
    const signResult = await signLoginPayload({ account, payload });
    console.log("SignResult", signResult);

    // Step 3: SERVER - Verifies the payload
    if (!(await thirdwebAuth.verifyPayload(signResult))) return null; // Double check
    const { data } = await axios.post<hpWhoAmI | null>(`auth/verifyPayload`, {
      signature: signResult.signature,
      payload: signResult.payload,
    });
    console.log("VerifyPayload", data);
    console.log("JWT", Cookies.get(COOKIE_NAME));
    console.log("Received HP", data);
    return data;
  } catch (error) {
    console.error("Login Error", error);
    return null;
  }
}
