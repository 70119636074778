// React
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
// External
import axios from "axios";

// Internal
import "./App.css";
import "./globals.css";

import { HpContext, HpProvider } from "../services/hp";
import { BACKEND_URL } from "../environment/variables";

// Pages
import PatientDetail from "../pages/patients/PatientDetail";
import Navbar from "../components/navbar";
import People from "../pages/patients/People";

import ErrorPage from "../pages/general/ErrorPage";
import Login from "../pages/general/Login";
import Loading from "../components/loading";

// Set the base URL globally at the root level of your application
axios.defaults.baseURL = BACKEND_URL;
axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.withCredentials = true; // Send cookies with requests
axios.defaults.xsrfCookieName = "XSRF-TOKEN";
axios.defaults.xsrfHeaderName = "X-XSRF-TOKEN";

function AppContent() {
  const hpContext = useContext(HpContext);
  const location = useLocation();
  const showNavbar =
    location.pathname !== "/" && location.pathname !== "/not-found";
  console.log("location", location.pathname, showNavbar);
  return (
    <>
      {showNavbar && <Navbar isLoading={false} />}
      {showNavbar && !(hpContext && hpContext.hp) && <Loading />}
      <div
        className={
          location.pathname === "/"
            ? ""
            : "max-w-screen-2xl mx-auto pt-24 pb-4 transition-all px-5"
        }
      >
        <Routes>
          <Route path="/" element={<Login />} />
          {
            // While trying to autoconnect, will only show loading and a Navbar
            // This way the load will exectute first and only then will redirect to the page (with a filled hpContext.hp), not found or back to login
            // This way the page waits for the outcome of autoconnect before sending to not found if failed
            hpContext && (hpContext.hp || hpContext.allowManualLogin) && (
              <>
                {hpContext.hp && (
                  <>
                    <Route path="/people" element={<People />} />
                    <Route
                      path="/people/:docId/:birth"
                      element={<PatientDetail />}
                    />
                  </>
                )}
                <Route
                  path="/not-found"
                  element={<ErrorPage mode="NOT_FOUND" />}
                />
                <Route path="*" element={<Navigate to="/not-found" />} />
              </>
            )
          }
        </Routes>
      </div>
    </>
  );
}

export default function App() {
  return (
    <BrowserRouter>
      <div className="min-h-screen bg-white">
        <ToastContainer />
        <HpProvider>
          <AppContent />
        </HpProvider>
      </div>
    </BrowserRouter>
  );
}
