// React
import React, { memo, useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

// Components
import PatientCard from "./PatientCard";
import InternalConsentsList from "../consent/InternalConsentsList";
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from "../../components/ui/dialog";
import { Button } from "../../components/ui/button";
import InternalConsentsCard from "../consent/InternalconsentCard";

// Hooks
import { HpContext } from "../../services/hp";

// Types
import { patient } from "../../types";

// Icons / Styles
import { HiArrowLongLeft } from "react-icons/hi2";
import showToast from "../../components/Toast";
import { transformDateString } from "../../utils/dateTimeUtils";

function PatientDetail() {
  // Context
  const hpContext = useContext(HpContext);
  const { docId, birth } = useParams();
  const [isLoading, setIsLoading] = useState(true);

  // Hooks
  const navigate = useNavigate();
  const isMounted = useRef(false);

  // Patient
  const [patient, setPatient] = useState<patient | null>(null);
  const [isInternalDialogOpen, setIsInternalDialogOpen] = useState(false);

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }
    navigate("/people");
  }, []);

  useEffect(() => {
    // Get the user by KYC
    const userByIdentity = async () => {
      try {
        const formatedBirth = transformDateString(birth);
        if (!formatedBirth) {
          showToast("Data de nascimento mal formatada", "error", 3000);
          return;
        }
        const response = await hpContext?.axiosInstance.get(
          `health-professional/userByIdentity/${docId}/${formatedBirth}`
        );
        console.log("Patient Detail", response?.data);
        if (response?.data === false) {
          showToast("Utilizador não encontrado", "error", 3000);
        } else {
          setPatient(response?.data);
          console.log("Patient Detail", response?.data);
        }
        // eslint-disable-next-line
      } catch (error: any) {
        const message = error.response?.data?.message;
        const status = error.response?.status;
        if (
          message &&
          message !== "Erro" &&
          status &&
          [400, 403, 409].includes(status)
          // 400 = bad request, 403 = forbidden, 409 = conflict, 500 = internal server error
        )
          showToast(error.response.data.message, "error", 3000);
        else showToast("Erro ao buscar utente", "error", 3000);
      } finally {
        setIsLoading(false);
      }
    };
    userByIdentity();
  }, [docId, birth]);

  return (
    <div>
      <div className="flex justify-between">
        <button
          onClick={() => navigate("/people")}
          className="flex items-center justify-center w-1/2 px-5 py-2 text-sm text-gray-700 transition-colors duration-200 bg-white border rounded-lg gap-x-2 sm:w-auto dark:hover:bg-gray-800 dark:bg-gray-900 hover:bg-gray-100 dark:text-gray-200 dark:border-gray-700 mb-2"
        >
          <HiArrowLongLeft className="w-6 h-6" />
          <span>Voltar</span>
        </button>
      </div>
      <div className="flex md:flex-row flex-col gap-4">
        <div className="grid grid-cols-1 md:w-[30%] w-full gap-y-2">
          <PatientCard patient={patient} isLoading={isLoading} />
          {/* Create Internal Consent Dialog */}
          <Dialog
            open={isInternalDialogOpen}
            onOpenChange={setIsInternalDialogOpen}
          >
            <DialogTrigger>
              <Button
                className="w-full"
                onClick={() => setIsInternalDialogOpen(true)}
              >
                {patient ? "Pedir Consentimento Informado" : "Criar Utente"}
              </Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-2xl min-h-8xl">
              {patient ? (
                <InternalConsentsCard
                  setDialogIsOpen={setIsInternalDialogOpen}
                  patient={patient}
                />
              ) : (
                <>Criar Utente</>
              )}
            </DialogContent>
          </Dialog>
        </div>
        <div className="flex flex-col md:pl-4 md:w-[70%] w-full gap-2">
          <InternalConsentsList patient={patient} />
        </div>
      </div>
    </div>
  );
}

export default memo(PatientDetail);
