import React, { useState, useEffect, useContext } from "react";
import { pdf } from "@react-pdf/renderer";
import { PDFDocument } from "pdf-lib";
import { HpContext } from "../../../services/hp";
import { Button } from "../../../components/ui/button";
import { IdentitiesTemplatePDF, RGPDTemplatePDF } from "./template";

// Merge PDFs function
const mergePdfs = async (
  createdPdfBytes: Uint8Array,
  existingPdfBase64: string
) => {
  try {
    // Convert base64 string to Uint8Array
    const existingPdfBytes = Uint8Array.from(atob(existingPdfBase64), (c) =>
      c.charCodeAt(0)
    );

    // Initialize PDFDocument for merging
    const mergedPdf = await PDFDocument.create();
    const createdPdf = await PDFDocument.load(createdPdfBytes);
    const existingPdf = await PDFDocument.load(existingPdfBytes);

    // Merge pages
    const [createdPage] = await mergedPdf.copyPages(createdPdf, [0]);
    mergedPdf.addPage(createdPage);

    // Copy pages from existing PDF
    const existingPages = await mergedPdf.copyPages(
      existingPdf,
      existingPdf.getPageIndices()
    );
    existingPages.forEach((page) => mergedPdf.addPage(page));

    return await mergedPdf.save();
  } catch (error) {
    console.error("Error merging PDFs:", error);
    throw error;
  }
};

const DownloadPdf = ({
  consentId,
  docId,
  birth,
}: {
  consentId: string;
  docId: string;
  birth: string;
}) => {
  const hpContext = useContext(HpContext);
  const [downloadUrl, setDownloadUrl] = useState<string | null>(null);
  const [internalConsent, setInternalConsent] = useState<any | null>(null);

  useEffect(() => {
    const fetchInternalConsent = async (consentId: string) => {
      try {
        const response = await hpContext?.axiosInstance.get(
          `/informed-consent/patient-download/${docId}/${birth}/${consentId}`
        );
        if (response?.data) {
          setInternalConsent(response.data);
        }
      } catch (error) {
        console.error("Error fetching internal consent:", error);
      }
    };
    if (consentId) {
      fetchInternalConsent(consentId);
    }
  }, [consentId]);

  useEffect(() => {
    const createAndMergePdf = async () => {
      // Convert the React component to PDF bytes
      if (!internalConsent) return;

      // Create the identities PDF
      const identitiesPdfBlob = await pdf(
        <IdentitiesTemplatePDF internalConsent={internalConsent} />
      ).toBlob();
      const identitiesPdfBytes = await identitiesPdfBlob.arrayBuffer();

      // Create the RGPD PDF
      const rgpdPdfBlob = await pdf(
        <RGPDTemplatePDF internalConsent={internalConsent} />
      ).toBlob();
      const rgpdPdfBytes = await rgpdPdfBlob.arrayBuffer();

      // Create the combined PDF ...

      // Merge PDFs
      const mergedPdfBytes = await mergePdfs(
        new Uint8Array(identitiesPdfBytes),
        internalConsent.template.fileContent
      );

      // Create a Blob URL for downloading
      const blob = new Blob([mergedPdfBytes], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);
      setDownloadUrl(url);
    };

    createAndMergePdf();
  }, [internalConsent]);

  return (
    <div>
      {downloadUrl && (
        <a href={downloadUrl} download="merged-document.pdf">
          <Button>Sim</Button>
        </a>
      )}
    </div>
  );
};

export default DownloadPdf;
