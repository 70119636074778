// React
import React, { useState } from "react";

// Components
import {
  CardDescription,
  CardTitle,
  CardHeader,
  CardContent,
} from "../../../components/ui/card";
import Loading from "../../../components/loading";
import { Button } from "../../../components/ui/button";
import showToast from "../../../components/Toast";
import { FaRegAddressCard } from "react-icons/fa";

interface Card2SignatureProps {
  handleNext: () => void;
}

const Card3Signature: React.FC<Card2SignatureProps> = ({ handleNext }) => {
  const [isReadingCard, setIsReadingCard] = useState(false);

  const handleButtonClick = () => {
    setIsReadingCard(true);
    const timer = setTimeout(() => {
      handleNext();
    }, 3000);
    return () => clearTimeout(timer);
  };

  return (
    <>
      <CardHeader>
        <CardTitle>
          {isReadingCard
            ? "A Ler o Cartão de Cidadão"
            : "Inserir Cartão de Cidadão"}
        </CardTitle>
        <CardDescription className="text-sm text-gray-800">
          {isReadingCard
            ? "Aguarde um momento por favor."
            : "Insira o cartão de cidadão do utente para assinar os consentimentos selecionados."}
        </CardDescription>
      </CardHeader>
      <CardContent className="flex flex-col justify-between">
        <div className="flex-grow flex items-center justify-center py-24">
          {isReadingCard ? (
            <Loading full={false} disableTimeout={true} />
          ) : (
            <FaRegAddressCard size={70} />
          )}
        </div>
        <Button
          className="bg-teal-700 hover:bg-teal-900 w-full"
          onClick={handleButtonClick} // Updated to use the new handler
          disabled={isReadingCard}
        >
          Ler Cartão
        </Button>
      </CardContent>
    </>
  );
};

export default Card3Signature;
