// src/pages/internalConsents/SelectTemplate.tsx

// React
import React, { useContext, useEffect, useMemo, useState } from "react";

// Components
import { Input } from "../../../components/ui/input";
import {
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../../components/ui/card";
import { HpContext } from "../../../services/hp";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTitle,
} from "../../../components/ui/dialog";
import Loading from "../../../components/loading";
import { Button } from "../../../components/ui/button";

// Types
import { Template } from "../../../types/informedConsentsTemplate";

// Add TypeScript types for props
interface Card2TemplatesProps {
  selectedTemplates: Template[];
  setSelectedTemplates: (templates: Template[]) => void;
  nextStep: () => void;
}

const Card2Templates = ({
  selectedTemplates,
  setSelectedTemplates,
  nextStep,
}: Card2TemplatesProps) => {
  const hpContext = useContext(HpContext);

  const [templates, setTemplates] = useState<Template[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [templateContent, setTemplateContent] = useState<string | null>(null);
  const [currentTemplate, setCurrentTemplate] = useState<Template | null>(null);

  // Inputs
  const [search, setSearch] = useState<string>("");

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        setLoading(true);
        const url =
          hpContext?.hp?.healthRoleName === "Secretária"
            ? `/consent-template/all/${search}`
            : `/consent-template/my/${search}`;
        const response = await hpContext?.axiosInstance.get(url);
        const data = response?.data;
        setTemplates(data);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchTemplates();
  }, []);

  const fetchPdfDetail = async (template: Template) => {
    try {
      const response = await hpContext?.axiosInstance.get(
        `consent-template/${template.id}`
      );
      setTemplateContent(response?.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (currentTemplate) {
      fetchPdfDetail(currentTemplate);
    }
  }, [currentTemplate]);

  // Filter templates based on the search term
  const filteredTemplates = useMemo(() => {
    return templates.filter(
      (template) =>
        template.name.toLowerCase().includes(search.toLowerCase()) ||
        template.description.toLowerCase().includes(search.toLowerCase())
    );
  }, [templates, search]);

  const handleSelectTemplate = (template: Template) => {
    if (selectedTemplates.includes(template)) {
      setSelectedTemplates(selectedTemplates.filter((t) => t !== template));
    } else {
      setSelectedTemplates([...selectedTemplates, template]);
    }
    if (currentTemplate === template) {
      setCurrentTemplate(null);
    }
  };

  const ListTemplates = () => {
    return (
      <div className="max-h-[28vh] overflow-y-auto">
        {filteredTemplates.map((template: Template) => (
          <div
            className={`flex h-1/8 rounded-sm transition-all items-center mb-1 shadow-sm ${
              selectedTemplates.includes(template)
                ? "bg-teal-600 text-white"
                : "bg-gray-50 hover:bg-gray-100 hover:scale-x-105"
            }`}
          >
            <span
              className="text-sm w-4/5 hover:cursor-pointer p-3 pl-4"
              onClick={() => handleSelectTemplate(template)} // Add template to the list
            >
              {template.name}
            </span>
            <div className="ml-auto flex space-x-2 w-1/5">
              <span
                className={`text-sm ${
                  selectedTemplates.includes(template)
                    ? "text-white"
                    : "text-gray-500 hover:text-gray-700"
                } hover:cursor-pointer`}
                onClick={(e) => {
                  e.stopPropagation(); // Prevent the click from selecting the template
                  setCurrentTemplate(template); // Set current template on click
                }}
              >
                Ver Detalhes
              </span>
              <Dialog
                open={currentTemplate === template}
                onOpenChange={() => setCurrentTemplate(null)}
              >
                <DialogContent>{TemplateDetails(template)}</DialogContent>
              </Dialog>
            </div>
          </div>
        ))}
        {filteredTemplates.length === 0 && (
          <div className="flex justify-center items-center min-h-60">
            <p className="text-gray-500">Nenhum consentimento encontrado</p>
          </div>
        )}
      </div>
    );
  };

  const selectedTemplatesRow = () => {
    return (
      <div className="flex flex-wrap h-10 items-center mb-2">
        {selectedTemplates.length === 0 ? (
          <div className="rounded-full bg-gray-200 text-gray-800 text-xs px-3 py-2">
            Nenhum consentimento selecionado
          </div>
        ) : (
          selectedTemplates.map((template, index) => (
            <div
              key={index}
              className="rounded-full bg-teal-600 text-white text-xs px-3 py-2 mr-1 hover:bg-red-300 hover:text-white cursor-pointer"
              onClick={() => handleSelectTemplate(template)} // Remove template on click
            >
              {template.name}
            </div>
          ))
        )}
      </div>
    );
  };

  const TemplateDetails = (template: Template) => {
    return (
      <div>
        <DialogTitle>{template.name}</DialogTitle>
        <DialogDescription>{template.description}</DialogDescription>
        {templateContent ? (
          <iframe
            className="h-[600px] w-full mt-5 border border-gray-400"
            src={`data:application/pdf;base64,${templateContent}`}
            title={template.name}
          />
        ) : (
          <div className="py-[200px]">
            <Loading full={false} />
          </div>
        )}
        <div className="flex justify-between mt-3">
          <Button variant="outline" onClick={() => setCurrentTemplate(null)}>
            Voltar
          </Button>
          <Button
            variant={
              selectedTemplates.includes(template) ? "destructive" : "default"
            }
            onClick={() => handleSelectTemplate(template)}
          >
            {selectedTemplates.includes(template)
              ? "Remover"
              : "Selecionar Consentimento"}
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div className="h-[50vh] flex flex-col">
      <CardHeader>
        <CardTitle>Escolha os Consentimentos</CardTitle>
        <CardDescription>
          Escolha os consentimentos que deseja pedir ao Utente
        </CardDescription>
      </CardHeader>
      <CardContent className="grid flex-grow">
        <div>
          <Input
            className="mb-2 w-1/2"
            placeholder="Pesquisar Consentimentos..."
            onChange={(e) => setSearch(e.target.value)}
          />
          {selectedTemplatesRow()}
          {loading ? (
            <div className="py-20">
              <Loading full={false} />
            </div>
          ) : (
            <ListTemplates />
          )}
        </div>
      </CardContent>
      <CardFooter className="justify-end space-x-2 mt-auto">
        <Button onClick={nextStep} disabled={selectedTemplates.length === 0}>
          Próximo
        </Button>
      </CardFooter>
    </div>
  );
};

export default Card2Templates;
