// React
import { createContext, useState, useEffect, ReactNode, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";

// Thirdweb
import { Account, Wallet, inAppWallet, smartWallet } from "thirdweb/wallets";
import { createThirdwebClient, defineChain } from "thirdweb";
import { baseSepolia } from "thirdweb/chains";

// External
import axios, { AxiosInstance } from "axios";
import Cookies from "js-cookie";

// Internal
import { hpWhoAmI } from "../types";
import {
  CLIENT_ID,
  COOKIE_NAME,
  FACTORY_ADDRESS,
  BACKEND_URL,
  COOKIE_LOGIN_HASH,
} from "../environment/variables";
import { login } from "../auth/auth";
import { Blockchain } from "./Blockchain";
import showToast from "../components/Toast";

type HpProviderProps = {
  children: ReactNode;
};

export type HpContextType = {
  eoaAccount: Account | null;
  smartAccount: Account | null;
  hp: hpWhoAmI | null;
  setHp: (hp: hpWhoAmI | null) => void;
  loginHash: string;
  allowManualLogin: boolean;
  manualLogin: (
    _hpInAppWallet: Wallet<"inApp">,
    _eoaAccount: Account,
    _smartAccount: Account,
    _hp: hpWhoAmI,
    _loginHash: string
  ) => void;
  logout: (logoutCall?: boolean) => Promise<void>;
  axiosInstance: AxiosInstance;
  blockchain: Blockchain;
} | null;

const HpContext = createContext<HpContextType>(null);
const HpProvider = ({ children }: HpProviderProps) => {
  // Hooks
  const navigate = useNavigate();
  const location = useLocation();

  // SESSION (unchanged) Variables
  const client = useMemo(
    () => createThirdwebClient({ clientId: CLIENT_ID }),
    []
  );
  const chain = useMemo(() => defineChain(baseSepolia), []);
  const blockchain = useMemo(
    () => new Blockchain(client, chain),
    [client, chain]
  );

  // Thirdweb
  const [hpInAppWallet, setHpInAppWallet] = useState<Wallet<"inApp"> | null>(
    null
  );
  const [eoaAccount, setEoaAccount] = useState<Account | null>(null); // Refresh Tokens
  const [smartAccount, setSmartAccount] = useState<Account>({} as Account); // Consent Transactions

  // State variables - HP
  const [hp, setHp] = useState<hpWhoAmI | null>(null);
  const [allowManualLogin, setAllowManualLogin] = useState<boolean>(false); // Disables manual login while tring to auto connect
  const [loginHash, setLoginHash] = useState<string>(
    Cookies.get(COOKIE_LOGIN_HASH) || ""
  );

  // Cant use useMemo so it updates cookies and xsrf token
  const axiosInstance = axios.create({
    baseURL: BACKEND_URL,
    timeout: 3000, // 2 seconds
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Cache-Control": "no-cache",
      "X-XSRF-TOKEN": Cookies.get("XSRF-TOKEN"),
    },
    withCredentials: true,
    xsrfCookieName: "XSRF-TOKEN",
    xsrfHeaderName: "X-XSRF-TOKEN",
    withXSRFToken: true,
  });

  // Request interceptor to add a timestamp parameter
  axiosInstance.interceptors.request.use(
    async function (config) {
      try {
        if (!eoaAccount) return Promise.reject("Erro");
        if (!Cookies.get(COOKIE_NAME)) {
          // Refresh Token if cookie expired
          const hp = await login(eoaAccount, false);
          if (!hp) {
            await logout();
            return Promise.reject("Erro");
          }
        }
        const timestamp = new Date().getTime();
        config.params = {
          ...config.params,
          _timestamp: timestamp,
        };
        return config;
      } catch (error) {
        return Promise.reject("Erro");
      }
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  useEffect(() => {
    if (eoaAccount) blockchain.updateAccounts(eoaAccount, smartAccount);
  }, [smartAccount]);

  /**
   * This useEffect is responsible for autoconnecting the user on page reload
   * If the user has already logged out, the login will fail
   * If anything fails, user is redirected to login page
   *
   * NOTE: Manual connect will be triggered on login page, and only at the end the info will be saved in hpContext
   */
  useEffect(() => {
    (async () => {
      // Try to connect to thirdweb, if it fails, allow login
      if (!loginHash) {
        setAllowManualLogin(true);
        navigate("/");
        return;
      }
      let _eoaAccount = eoaAccount;
      if (!_eoaAccount) {
        _eoaAccount = await _autoConnect();
        if (!_eoaAccount) {
          setAllowManualLogin(true);
          navigate("/");
          return;
        }
      }
      // Try to get HP info, with the token (if the dosent exist get it first )
      // If loggoed out, it has no cookie and _login will fail at the first call
      const _hp = hp
        ? hp
        : Cookies.get(COOKIE_NAME)
        ? await _whoAmI()
        : await login(_eoaAccount, false);
      if (_hp) {
        setHp(_hp);
        // Sucess if in login, then goes to people
        if (location.pathname === "/") navigate("/people");
      } else await logout();
    })();
  }, []);

  const _autoConnect = async () => {
    try {
      const _inAppWallet = inAppWallet();
      const _eoaAccount = await _inAppWallet.autoConnect({ client, chain });
      setEoaAccount(_eoaAccount);
      const smart = smartWallet({
        factoryAddress: FACTORY_ADDRESS,
        gasless: true,
        chain: chain,
      });
      setSmartAccount(
        await smart.connect({
          client,
          personalAccount: _eoaAccount,
        })
      );
      setHpInAppWallet(_inAppWallet);
      return _eoaAccount;
    } catch {
      return null;
    }
  };

  // Called when HP changes active country. Gets the HP info for the new country
  async function _whoAmI(): Promise<hpWhoAmI | null> {
    try {
      return (
        await axios.get("/health-professional", {
          withCredentials: true,
        })
      ).data;
    } catch (error) {
      console.error("Erro ao fazer whoami", error);
      return null;
    }
  }

  const manualLogin = (
    _hpInAppWallet: Wallet<"inApp">,
    _eoaAccount: Account,
    _smartAccount: Account,
    _hp: hpWhoAmI,
    _loginHash: string
  ) => {
    setHpInAppWallet(_hpInAppWallet);
    setEoaAccount(_eoaAccount);
    setSmartAccount(_smartAccount);
    setHp(_hp);
    setLoginHash(_loginHash);
    Cookies.set(COOKIE_LOGIN_HASH, _loginHash); // SETTINGS
  };

  async function logout() {
    console.log("logout");
    try {
      if (Cookies.get(COOKIE_NAME)) {
        try {
          await axiosInstance.post("/auth/logOut", {});
        } catch {} // Proceed with rest of logout (could cause auto connect to force connect )
      }
      // Remove all cookies
      const cookies = document.cookie.split(";");
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf("=");
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        Cookies.remove(name);
      }
      // Remove all local storage
      localStorage.clear();
      sessionStorage.clear();

      // Remove cookie
      Cookies.remove(COOKIE_NAME);

      // Logout from thirdweb ()
      if (hpInAppWallet) await hpInAppWallet.disconnect(); // not working

      // Reset HP states
      if (hp) setHp(null);
      if (!allowManualLogin) setAllowManualLogin(true);

      // Reset thirdweb states
      if (hpInAppWallet) setHpInAppWallet(null);
      if (eoaAccount) setEoaAccount(null);
      if (smartAccount) setSmartAccount({} as Account);
      if (Cookies.get(COOKIE_LOGIN_HASH)) Cookies.remove(COOKIE_LOGIN_HASH);
      navigate("/");
    } catch (error) {
      console.error("Erro ao fazer logout", error);
      showToast("Erro ao fazer logout", "error", 3000);
    }
  }
  return (
    <HpContext.Provider
      value={{
        eoaAccount,
        smartAccount,
        hp,
        setHp,
        loginHash,
        allowManualLogin,
        manualLogin,
        logout,
        axiosInstance,
        blockchain,
      }}
    >
      {children}
    </HpContext.Provider>
  );
};
export { HpContext, HpProvider };
