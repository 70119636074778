// React
import React, { memo, useContext, useEffect, useState } from "react";

// Hooks

// Components
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/ui/table";
import { CardDescription, CardTitle } from "../../components/ui/card";
import Loading from "../../components/loading";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "../../components/ui/dialog";

// Shadcn

// Types
import { HpContext } from "../../services/hp";
import { formatDate } from "../../utils/dateTimeUtils";
import DownloadPdf from "./pdf/generatePdf";
import { Button } from "../../components/ui/button";
import { informedConsentList, patient } from "../../types";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../components/ui/tooltip";
import { QuestionMarkCircledIcon } from "@radix-ui/react-icons";
import { IoDownloadOutline } from "react-icons/io5";

interface informedConsentsListProps {
  patient: patient | null;
}

function informedConsentsList({ patient }: informedConsentsListProps) {
  const hpContext = useContext(HpContext);

  const [informedConsents, setinformedConsents] = useState<
    informedConsentList[]
  >([]);
  const [informedConsents2, setinformedConsents2] = useState<
    informedConsentList[]
  >([]);
  const [selectedConsentId, setSelectedConsentId] = useState<string | null>(
    null
  );
  const [isLoading, setIsLoading] = useState(true);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "a") {
        setinformedConsents2(informedConsents);
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [informedConsents]);

  const fetchinformedConsents = async () => {
    try {
      const response = await hpContext?.axiosInstance.get(
        `/informed-consent/patient-informed-consents/${patient?.docId}/${patient?.birth}`
      );
      if (!response?.data) throw new Error("Erro");
      const data = response.data;
      setinformedConsents(data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!patient) {
      setIsLoading(false);
      setinformedConsents([]);
      return;
    }
    fetchinformedConsents();
  }, [patient]);

  const handleDownloadClick = (consentId: string) => {
    setSelectedConsentId(consentId);
    setIsDialogOpen(true);
  };

  const colorLegend = () => {
    return (
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger>
            <QuestionMarkCircledIcon />
          </TooltipTrigger>
          <TooltipContent>
            <div className="flex items-center">
              <div className="h-3 w-3 bg-green-500 rounded-full mr-2"></div>
              <p style={{ fontWeight: "normal" }}>
                Todos os envolvidos assinaram
              </p>
            </div>
            <div className="flex items-center">
              <div className="h-3 w-3 bg-yellow-500 rounded-full mr-2"></div>
              <p style={{ fontWeight: "normal" }}>
                Pelo menos um responsável não assinou
              </p>
            </div>
            <div className="flex items-center">
              <div className="h-3 w-3 bg-red-500 rounded-full mr-2"></div>
              <p style={{ fontWeight: "normal" }}>O utente não assinou</p>
            </div>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    );
  };

  return (
    <div>
      <CardTitle>Consentimentos Informados</CardTitle>
      <CardDescription className="mb-3">
        Lista dos últimos consentimentos informados do utente
      </CardDescription>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className="flex items-center gap-2">
              {colorLegend()}
              <span>Consentimento Informado</span>
            </TableHead>
            <TableHead>Pedido Por</TableHead>
            <TableHead>Responsáveis</TableHead>
            <TableHead>Id Processo</TableHead>
            <TableHead>Data</TableHead>
          </TableRow>
        </TableHeader>
        {!isLoading ? (
          <TableBody className="hover:cursor-pointer">
            {informedConsents2.length > 0 ? (
              informedConsents2.map((informedConsent, index) => (
                <TableRow key={index}>
                  <TableCell className="font-medium flex items-center gap-3">
                    <div
                      className={`h-3 w-3 ${
                        !informedConsent.userTxHash
                          ? "bg-red-500" // user didnt sign
                          : informedConsent.responsableHpTxHashes.some(
                              (hash) => hash === ""
                            )
                          ? "bg-yellow-500" // some responsible didnt sign
                          : "bg-green-500" // all signed
                      } rounded-full`}
                    ></div>
                    {/* <a
                      href="/Anestesia Geral - ULS Santsa Maria.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "underline", color: "black" }}
                    > */}
                    <span
                      className="text-teal-900 hover:text-teal-500 transition-colors hover:underline"
                      onClick={() => handleDownloadClick(informedConsent.id)}
                    >
                      {informedConsent.template.name}
                    </span>
                    {/* </a> */}
                  </TableCell>
                  <TableCell>
                    <span
                      key={index}
                      className={`text-gray-600 px-2 py-1 rounded-lg text-xs`}
                      style={{
                        backgroundColor: "#D5F5E3", // Example color for all records
                      }}
                    >
                      {informedConsent.askedBy.clinicalName}
                    </span>
                  </TableCell>
                  <TableCell>{["David Berahnu"].join(", ")}</TableCell>
                  <TableCell>{informedConsent.processId.slice(0, 8)}</TableCell>
                  <TableCell>{formatDate(informedConsent.createdAt)}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5}>
                  Nenhum consentimento informado encontrado
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        ) : (
          <TableBody className="hover:cursor-pointer">
            <TableRow>
              <TableCell colSpan={5} className="py-10">
                <Loading full={false} />
              </TableCell>
            </TableRow>
          </TableBody>
        )}
      </Table>
      <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Download do Consentimento</DialogTitle>
            <DialogDescription>
              Deseja fazer o download do consentimento em formato PDF?
            </DialogDescription>
          </DialogHeader>
          <div className="flex justify-end gap-2">
            <Button variant="outline" onClick={() => setIsDialogOpen(false)}>
              Não
            </Button>
            <DownloadPdf
              consentId={selectedConsentId!}
              docId={patient?.docId!}
              birth={patient?.birth!}
            />
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default memo(informedConsentsList);
