import React, { useContext } from "react";
import { HpContext } from "../services/hp";

// UI
import {
  DrawerClose,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
} from "../components/ui/drawer";
import { Button } from "./ui/button";
import { MdContentCopy } from "react-icons/md";
import showToast from "./Toast";

const HealthProfessional: React.FC = () => {
  const hpContext = useContext(HpContext);

  const AvatarImage = () => {
    return (
      <div className="w-32 h-32 rounded-full overflow-hidden mr-3">
        <img
          src={
            hpContext?.hp?.photo
              ? `data:image/png;base64,${hpContext?.hp?.photo}`
              : // eslint-disable-next-line
                require("./images/profile.jpg")
          }
          alt="Profile"
          className="w-32 h-32 object-cover"
        />
      </div>
    );
  };

  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text);
    showToast("Copiado com sucesso", "success", 3000);
  };

  return (
    <div>
      <div className="mx-auto w-full max-w-sm">
        <DrawerHeader className="flex flex-col items-center">
          <AvatarImage />
          <DrawerTitle>
            <span className="text-xl font-bold text-center">
              {hpContext?.hp?.clinicalName}{" "}
            </span>
            <span className="bg-[#E3EBF7] text-gray-500 px-3 py-2 rounded-md ml-2 text-xs">
              {hpContext?.hp?.healthRoleName}
            </span>
          </DrawerTitle>
          <DrawerDescription className="text-center"></DrawerDescription>
        </DrawerHeader>
        <DrawerDescription>
          <div className="flex justify-between py-3">
            <span className="font-semibold text-gray-800">Cédula</span>
            <span className="flex items-center">
              {hpContext?.hp?.clinicalNumber}
              <button
                onClick={() => handleCopy(hpContext?.hp?.clinicalNumber || "")}
                className="ml-2"
              >
                <MdContentCopy size={16} />
              </button>
            </span>
          </div>
          <hr className="border-t border-gray-300" />
          <div className="flex justify-between py-3">
            <span className="font-semibold text-gray-800">Organização</span>
            <span className="flex items-center">
              {hpContext?.hp?.healthOrganizationName}
              <button
                onClick={() =>
                  handleCopy(hpContext?.hp?.healthOrganizationName || "")
                }
                className="ml-2"
              >
                <MdContentCopy size={16} />
              </button>
            </span>
          </div>
          <hr className="border-t border-gray-300" />
          <div className="flex justify-between py-3">
            <span className="font-semibold text-gray-800">Nº Interno</span>
            <span className="flex items-center">
              {hpContext?.hp?.internalNumber}
              <button
                onClick={() => handleCopy(hpContext?.hp?.internalNumber || "")}
                className="ml-2"
              >
                <MdContentCopy size={16} />
              </button>
            </span>
          </div>
          <hr className="border-t border-gray-300" />
          <div className="flex justify-between py-3">
            <span className="font-semibold text-gray-800">Email</span>
            <span className="flex items-center">
              {hpContext?.hp?.institutionalEmail}
              <button
                onClick={() =>
                  handleCopy(hpContext?.hp?.institutionalEmail || "")
                }
                className="ml-2"
              >
                <MdContentCopy size={16} />
              </button>
            </span>
          </div>
          <hr className="border-t border-gray-300" />
          <div className="flex justify-between py-3">
            <span className="font-semibold text-gray-800">Departamentos</span>
            <span className="flex items-center">
              {hpContext?.hp?.healthDepartmentsNames}
              <button
                onClick={() =>
                  navigator.clipboard.writeText(
                    hpContext?.hp?.healthDepartmentsNames?.join(", ") || ""
                  )
                }
                className="ml-2"
              >
                <MdContentCopy size={16} />
              </button>
            </span>
          </div>

          <Button
            variant="destructive"
            className="w-full mt-5 bg-[#FF8474] hover:bg-[#FF4C4C] transition duration-300"
            onClick={() => hpContext?.logout()}
          >
            Logout
          </Button>
          <DrawerClose asChild>
            <Button variant="outline" className="w-full mt-2">
              Fechar
            </Button>
          </DrawerClose>
        </DrawerDescription>
        <DrawerFooter></DrawerFooter>
      </div>
    </div>
  );
};

export default HealthProfessional;
