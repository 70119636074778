// React
import React, { useState } from "react";

// Components
import Card1VerifyIdentity from "./tabs/Card1VerifyIdentity";
import Card2Templates from "./tabs/Card2Templates";
import Card3Signature from "./tabs/Card3Signature";

// Types
import { Template } from "../../types/informedConsentsTemplate";
import PasswordInput from "./tabs/Card0PasswordInput";
import { patient } from "../../types";
import Card4OTPInput from "./tabs/Card4OTPInput";

interface InternalConsentsCardProps {
  setDialogIsOpen: (value: boolean) => void;
  patient: patient | null;
  prevStep?: () => void;
}

const InternalConsentsCard: React.FC<InternalConsentsCardProps> = ({
  setDialogIsOpen,
  patient,
}) => {
  const [step, setStep] = useState(0);
  const [selectedTemplates, setSelectedTemplates] = useState<Template[]>([]);

  const handleNext = () => {
    setStep((prevStep) => prevStep + 1);
  };

  return (
    <div>
      {step === 0 && <PasswordInput handleNext={handleNext} />}
      {step === 1 && (
        <Card1VerifyIdentity patient={patient} nextStep={handleNext} />
      )}
      {step === 2 && (
        <Card2Templates
          selectedTemplates={selectedTemplates}
          setSelectedTemplates={setSelectedTemplates}
          nextStep={handleNext}
        />
      )}
      {step === 3 && <Card3Signature handleNext={handleNext} />}
      {step === 4 && <Card4OTPInput setDialogIsOpen={setDialogIsOpen} />}
    </div>
  );
};

export default InternalConsentsCard;
