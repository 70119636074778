// PEOPLE - LIST INFORMED CONSENTS
export enum informedConsentListEnum {
  askedByMe = "askedByMe",
  responsible = "responsible",
  myDepartments = "myDepartments",
}

export type informedConsentListQueryRaw = {
  type: informedConsentListEnum;
  page: number;
  processId: string;
  templateName: string;
  patientIdentifier: string;
  hpIdentifier: string;
};

export type informedConsentListQuery = {
  type: informedConsentListEnum;
  page: number;
  withStats: boolean;
  processId?: string;
  templateName?: string;
  patientIdentifier?: string;
  hpIdentifier?: string;
};

// list
export type informedConsentList = {
  id: string;
  processId: string;
  template: {
    name: string;
  };
  user: {
    fullName: string; // display in table
    birth: string; // patient page
    docId: string; // patient page
  };
  askedBy: {
    clinicalName: string;
    internalNumber: string; // REMOVER
    clinicalNumber: string; // REMOVER
  };
  responsableHps: {
    clinicalName: string;
    internalNumber: string; // REMOVER
    clinicalNumber: string; // REMOVER
  }[];
  userTxHash?: string;
  responsableHpTxHashes: string[];
  createdAt: string; // Instead of Date
};
export type informedConsentListStats = {
  responsible: number;
  askedByMe: number;
  myDepartments: number;
};
export type informedConsentListResponse = {
  informedConsents: informedConsentList[];
  length: number;
  stats?: informedConsentListStats;
};

// PATIENT - LIST HIS INFORMED CONSENTS and DOWNLOAD
export type patientInformedConsentList = {
  processId: string;
  template: {
    name: string;
  };
  askedBy: {
    clinicalName: string;
    internalNumber: string;
    clinicalNumber: string;
  };
  responsableHps: {
    clinicalName: string;
    internalNumber: string;
    clinicalNumber: string;
  }[];
  userTxHash: string;
  responsableHpTxHashes: string[];
}[];

export type patientInformedConsentDownload = {
  processId: string;
  template: {
    name: string;
    fileContent: string;
    hash: string;
  };
  askedBy: {
    hpWallet: {
      wallet: string;
    };
    clinicalName: string;
    internalNumber: string;
    clinicalNumber: string;
    healthOrganization: {
      name: string;
      legalName: string;
      address: string;
    };
  };
  responsableHps: {
    clinicalName: string;
    internalNumber: string;
    clinicalNumber: string;
  }[];
  user: {
    wallet: string;
    identities: {
      fullName: string;
      birth: string;
      docId: string;
    }[];
  };
  createdAt: Date;
};

// ASK INFORMED CONSENT

//get informed consent responsible to assing to a template
export type informedConsentResponsible = {
  id: string;
  clinicalName: string;
  internalNumber: string;
  clinicalNumber: string;
  photo?: string;
};

//create informed consent
export type createInformedConsentsDto = {
  userId: string;
  informedConsents: {
    templateId: string;
    responsableHpIds: string[];
  }[];
};
