// React
import React, { useContext, useState } from "react";

// Components
import { Input } from "../../../components/ui/input";
import { Button } from "../../../components/ui/button";

// Context
import { HpContext } from "../../../services/hp";

// Utility
import showToast from "../../../components/Toast";
import { keccak256 } from "ethers/lib/utils";
import { toUtf8Bytes } from "ethers/lib/utils";
import { useNavigate } from "react-router-dom";
import {
  CardContent,
  CardDescription,
  CardTitle,
} from "../../../components/ui/card";
import { CiLock } from "react-icons/ci";
import { CiUnlock } from "react-icons/ci";

const PasswordInput = ({ handleNext }: { handleNext: () => void }) => {
  // Context
  const hpContext = useContext(HpContext);
  const navigate = useNavigate();

  // State
  const [password, setPassword] = useState<string>("");
  const [isCorrect, setIsCorrect] = useState<boolean>(false);

  const handleConfirm = () => {
    const loginHash = document.cookie
      .split("; ")
      .find((row) => row.startsWith("loginHash="))
      ?.split("=")[1];

    if (loginHash) {
      const computedHash = keccak256(
        toUtf8Bytes(
          [
            hpContext?.hp?.username,
            hpContext?.hp?.institutionalEmail,
            password,
            "HUMANOS",
          ].join("")
        )
      );

      if (computedHash === loginHash) {
        setIsCorrect(true);
        setTimeout(() => {
          handleNext();
        }, 600); // Wait one second before passing to the next page
      } else {
        showToast("Password incorreta. Tente novamente.", "error", 3000);
      }
    } else {
      navigate("/");
    }
  };

  return (
    <div className="flex flex-col h-full">
      <CardContent className="grid gap-2">
        <CardTitle>Confirme a sua password</CardTitle>
        <CardDescription>
          Confirme a sua password para continuar.
        </CardDescription>
      </CardContent>
      <div className="flex flex-col items-center justify-center h-[20vh]">
        <div className="flex flex-col items-center gap-2">
          {isCorrect ? (
            <CiUnlock className="text-6xl" />
          ) : (
            <CiLock className="text-6xl" />
          )}
          <Input
            type="password"
            placeholder="Password"
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleConfirm(); // Call handleConfirm on Enter key press
              }
            }}
          />
          <Button className="w-full" onClick={handleConfirm}>
            Confirmar
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PasswordInput;
