// Import Firebase functions
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
  createUserWithEmailAndPassword,
  getAuth,
  signInWithEmailAndPassword,
} from "firebase/auth";

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBkIPpYvjn--dl4FAwGDXFVAR0cAiQNugc",
  authDomain: "humanos-5ed93.firebaseapp.com",
  projectId: "humanos-5ed93",
  storageBucket: "humanos-5ed93.firebasestorage.app",
  messagingSenderId: "471254351258",
  appId: "1:471254351258:web:ae5cd0bd4c83ac3dc4ce23",
  measurementId: "G-7PEFTHVEXT",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
const auth = getAuth(app); // Initialize Auth

export { auth, signInWithEmailAndPassword, createUserWithEmailAndPassword };
