export const FRONTEND_DOMAIN = "http://localhost:3000";
export const BACKEND_DOMAIN = "http://localhost:8000";
export const BACKEND_URL = `${BACKEND_DOMAIN}/v0/`;

// Thirdweb
export const CLIENT_ID = "59adb8214b2922e6b1244b1325eac728"; // Main: "59adb8214b2922e6b1244b1325eac728"; Informed Consent: "eed9d8560992686af3fbdd683a110dba"
export const CC_CLIENT_ID = "cdab6ce9ae2a182e2852c5327ba1c762";
export const FACTORY_ADDRESS = "0x035431c6cf4DBd31fA278aB42a4817Abc70DfD8B"; // Main: "0x035431c6cf4DBd31fA278aB42a4817Abc70DfD8B"; Informed Consent: "0xD6828068D5b9CB3A66049F3918512894632dAC85"
export const ENCRYPTION_KEY =
  "a1b2c3d4e5f6g7h8i9j0k1l2m3n4o5p6q7r8s9t0u1v2w3x4y5z";

// Blockchain
export const USERID_SALT = "HUMANOS";

export const COOKIE_NAME = "jwt";
export const COOKIE_LOGIN_HASH = "loginHash";
export const CACHE_COOKIE_OPTIONS = {
  domain: "localhost",
  expires: 5, // 5 days
  path: "/",
};

// PIN
export const PIN_INACTIVITY = 6000000; // 10 min
export const PIN_TIMEOUT = 3600000; // 1h

export const OTP_SPACING = 30; // seconds

// String, Search, Pagination Properties
export const minNameLength = 5;
export const profileNameMax = 30;
export const profileNameMaxWords = 3;

export const affiliationNameMax = 30;
export const affiliationNameMinWords = 2;
export const affiliationNameMaxWords = 5;

export const SEARCH_DEBOUNCE_TIME = 1000; // ms 1000 = 1s
export const PARAMS_DEBOUNCE_TIME = 1000; //ms

export const PAGE_SIZE = 20;

export const TOOLTIP_LENGTH = 40;
