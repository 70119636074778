// React
import React, { useCallback, useContext, useEffect, useState } from "react";

// Hooks
import { useNavigate } from "react-router-dom";

// Components
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../components/ui/card";
import Loading from "../../components/loading";
import { HpContext } from "../../services/hp";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/ui/table";
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "../../components/ui/pagination";
import { Input } from "../../components/ui/input";
import showToast from "../../components/Toast";
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from "../../components/ui/dialog";
import { Button } from "../../components/ui/button";
import SearchPatient from "./SearchPatient";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../components/ui/tooltip";

// Icons / Styles
import { PiShareNetworkLight } from "react-icons/pi";
import { TbBuildingHospital } from "react-icons/tb";
import { MdOutlineMedicalInformation } from "react-icons/md";
import { QuestionMarkCircledIcon } from "@radix-ui/react-icons";

// Environment Variables
import {
  PAGE_SIZE,
  PARAMS_DEBOUNCE_TIME,
  TOOLTIP_LENGTH,
} from "../../environment/variables";
import {
  HpFilledContextType,
  informedConsentList,
  informedConsentListEnum,
  informedConsentListQuery,
  informedConsentListQueryRaw,
  informedConsentListResponse,
  informedConsentListStats,
} from "../../types";
import { firstAndLastName, subString } from "../../utils/stringUtils";
import { dummyInformedConsents } from "./dummyData";
import { formatDate } from "../../utils/dateTimeUtils";

const People = () => {
  // Context
  const hpContext = useContext(HpContext) as HpFilledContextType;
  const navigate = useNavigate();

  const [informedConsents, setInformedConsents] = useState<
    informedConsentList[]
  >([]);
  const [stats, setStats] = useState<informedConsentListStats | undefined>();
  const [maxPages, setMaxPages] = useState<number>(1);

  const [params, setParams] = useState<informedConsentListQueryRaw>({
    type: informedConsentListEnum.responsible,
    page: 1,
    processId: "",
    templateName: "",
    patientIdentifier: "",
    hpIdentifier: "",
  });

  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [inPageLoading, setInPageLoading] = useState(false);

  const fetchInformedConsents = useCallback(async () => {
    try {
      let query: informedConsentListQuery = {
        type: params.type,
        page: params.page,
        withStats: !stats,
      };
      if (params.processId) query.processId = params.processId;
      if (params.templateName) query.templateName = params.templateName;
      if (params.patientIdentifier)
        query.patientIdentifier = params.patientIdentifier;
      if (params.hpIdentifier) query.hpIdentifier = params.hpIdentifier;

      const { data } =
        await hpContext.axiosInstance.get<informedConsentListResponse>(
          `informed-consent/all`,
          {
            withCredentials: true,
            params: query,
          }
        );
      console.log("fetchInformedConsents data", data);
      if (data) {
        setInformedConsents(data.informedConsents);
        setMaxPages(Math.max(1, Math.ceil(data.length / PAGE_SIZE)));
        if (!stats) setStats(data.stats);
      }
    } catch (error: any) {
      console.error("fetchUtentes error", error);
      const message = error.response?.data?.message;
      const status = error.response?.status;
      if (
        message &&
        message !== "Erro" &&
        status &&
        [400, 403, 409].includes(status)
        // 400 = bad request, 403 = forbidden, 409 = conflict, 500 = internal server error
      )
        showToast(error.response.data.message, "error", 3000);
      else showToast("Erro ao procurar utentes a partilhar", "error", 3000);
    } finally {
      setInPageLoading(false);
    }
    // eslint-disable-next-line
  }, [params]);

  // Runs on start, and every time fetchPatients dependency changes
  useEffect(() => {
    setInPageLoading(true);
    const handler = setTimeout(() => {
      fetchInformedConsents();
    }, PARAMS_DEBOUNCE_TIME);
    return () => {
      clearTimeout(handler);
    };
  }, [fetchInformedConsents]);

  // Informed Consent Stats (Professional, Department, Organization)
  const ConsentStats = () => {
    return (
      <div className="hidden grid-cols-2 gap-2 md:grid ">
        <Card
          onClick={() => {
            if (params.type !== informedConsentListEnum.responsible)
              setParams({
                ...params,
                type: informedConsentListEnum.responsible,
                page: 1,
              });
          }}
          className="hover:cursor-pointer hover:scale-105 transition-all hover:shadow-md"
        >
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2 pt-4">
            <CardTitle className="text-sm font-medium">
              Assinados por mim
            </CardTitle>
            <PiShareNetworkLight />
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">12</div>
            <p className="text-xs text-muted-foreground">
              Documentos assinados por mim.
            </p>
          </CardContent>
        </Card>
        {/*
        <Card
          onClick={() => {
            if (params.type !== informedConsentListEnum.askedByMe)
              setParams({
                ...params,
                type: informedConsentListEnum.askedByMe,
                page: 1,
              });
          }}
          className="hover:cursor-pointer hover:scale-105 transition-all hover:shadow-md"
        >
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2 pt-4">
            <CardTitle className="text-sm font-medium">
              Pedidos por mim
            </CardTitle>
            <MdOutlineMedicalInformation />
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">43</div>
            <p className="text-xs text-muted-foreground">
              Documentos pedidos por mim.
            </p>
          </CardContent>
        </Card>
        */}
        <Card
          onClick={() => {
            if (params.type !== informedConsentListEnum.myDepartments)
              setParams({
                ...params,
                type: informedConsentListEnum.myDepartments,
                page: 1,
              });
          }}
          className="hover:cursor-pointer hover:scale-105 transition-all hover:shadow-md"
        >
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2 pt-4">
            <CardTitle className="text-sm font-medium">
              Do meu Serviço
            </CardTitle>
            <TbBuildingHospital />
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">144</div>
            <p className="text-xs text-muted-foreground">
              Documentos do meu Serviço.
            </p>
          </CardContent>
        </Card>
      </div>
    );
  };

  const colorLegend = () => {
    return (
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger>
            <QuestionMarkCircledIcon />
          </TooltipTrigger>
          <TooltipContent>
            <div className="flex items-center">
              <div className="h-3 w-3 bg-green-500 rounded-full mr-2"></div>
              <p style={{ fontWeight: "normal" }}>
                Todos os envolvidos assinaram
              </p>
            </div>
            <div className="flex items-center">
              <div className="h-3 w-3 bg-yellow-500 rounded-full mr-2"></div>
              <p style={{ fontWeight: "normal" }}>
                Pelo menos um responsável não assinou
              </p>
            </div>
            <div className="flex items-center">
              <div className="h-3 w-3 bg-red-500 rounded-full mr-2"></div>
              <p style={{ fontWeight: "normal" }}>O utente não assinou</p>
            </div>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    );
  };

  // Informed Consent Table
  const ConsentTable = () => {
    return (
      <div>
        {/* For NOW JUST THIS SEARCH, then TRANSFORM TO EXCEL*/}
        <Input
          type="text"
          onChange={(e) =>
            setParams({ ...params, patientIdentifier: e.target.value, page: 1 })
          }
          value={params.patientIdentifier}
          placeholder="Pesquisar Utentes..."
          className="border w-1/3 mt-4 mb-2"
        />
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="flex items-center gap-2">
                {colorLegend()}
                <span>Utente</span>
              </TableHead>
              <TableHead>Consentimento Informado</TableHead>
              <TableHead>Pedido Por</TableHead>
              <TableHead>Responsáveis</TableHead>
              <TableHead>Id Processo</TableHead>
              <TableHead>Data</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody className="hover:cursor-pointer">
            {inPageLoading ? (
              <TableCell colSpan={6}>
                <div className="flex justify-center items-center text-center py-32 h-full">
                  <Loading full={false} />
                </div>
              </TableCell>
            ) : (
              <>
                {informedConsents.concat(dummyInformedConsents).length > 0 ? (
                  informedConsents
                    .concat(dummyInformedConsents)
                    .map((informedConsent, index) => (
                      <TableRow
                        key={index}
                        onClick={() =>
                          navigate(
                            `/people/${informedConsent.user.docId}/${informedConsent.user.birth}`
                          )
                        }
                      >
                        <TableCell className="flex items-center">
                          {/* No user hash -> Red ; Missing responsible hash --> Yellow; Green */}
                          <div
                            className={`h-3 w-3 mr-3 ${
                              !informedConsent.userTxHash
                                ? "bg-red-500" // user didnt sign
                                : informedConsent.responsableHpTxHashes.some(
                                    (hash) => hash === ""
                                  )
                                ? "bg-yellow-500" // some responsible didnt sign
                                : "bg-green-500" // all signed
                            } rounded-full`}
                          ></div>
                          <TooltipProvider>
                            <Tooltip>
                              <TooltipTrigger>
                                {firstAndLastName(
                                  informedConsent.user.fullName
                                )}
                              </TooltipTrigger>
                              <TooltipContent>
                                <div className="flex items-center gap-2">
                                  {`${informedConsent.user.fullName}`}
                                  <div
                                    className={`flex h-3 w-3 ${
                                      informedConsent.userTxHash
                                        ? "bg-green-500"
                                        : "bg-red-500" // user didnt sign
                                    } rounded-full`}
                                  ></div>
                                </div>
                              </TooltipContent>
                            </Tooltip>
                          </TooltipProvider>
                        </TableCell>
                        <TableCell>
                          <TooltipProvider>
                            <Tooltip>
                              <TooltipTrigger>
                                {subString(informedConsent.template.name)}
                              </TooltipTrigger>
                              {informedConsent.template.name.length >
                                TOOLTIP_LENGTH && (
                                <TooltipContent>
                                  {informedConsent.template.name}
                                </TooltipContent>
                              )}
                            </Tooltip>
                          </TooltipProvider>
                        </TableCell>
                        <TableCell>
                          <TooltipProvider>
                            <Tooltip>
                              <TooltipTrigger>
                                {subString(
                                  informedConsent.askedBy.clinicalName
                                )}
                              </TooltipTrigger>
                              <TooltipContent>
                                {informedConsent.askedBy.clinicalName}
                              </TooltipContent>
                            </Tooltip>
                          </TooltipProvider>
                        </TableCell>
                        <TableCell>
                          <TooltipProvider>
                            <Tooltip>
                              <TooltipTrigger>
                                {subString(
                                  informedConsent.responsableHps
                                    .map((hp) => hp.clinicalName)
                                    .join(", ")
                                )}
                              </TooltipTrigger>
                              <TooltipContent>
                                <div className="flex flex-col gap-2">
                                  {informedConsent.responsableHps.map(
                                    (hp, index) => (
                                      <div className="flex items-center gap-2">
                                        {`${hp.clinicalName}`}
                                        <div
                                          className={`flex h-3 w-3 ${
                                            !!informedConsent
                                              .responsableHpTxHashes[index]
                                              ? "bg-green-500"
                                              : "bg-red-500" // user didnt sign
                                          } rounded-full`}
                                        ></div>
                                      </div>
                                    )
                                  )}
                                </div>
                              </TooltipContent>
                            </Tooltip>
                          </TooltipProvider>
                        </TableCell>
                        <TableCell>{informedConsent.processId}</TableCell>
                        <TableCell>
                          {formatDate(informedConsent.createdAt)}
                        </TableCell>
                      </TableRow>
                    ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={6}>
                      {" "}
                      Nenhum consentimento informado...
                    </TableCell>
                  </TableRow>
                )}
              </>
            )}
          </TableBody>
        </Table>
      </div>
    );
  };

  // Pagination
  const TablePagination = () => {
    return (
      <div>
        {true && ( // utentes.length > 0
          <Pagination className="mt-5">
            <PaginationContent>
              <PaginationItem>
                <PaginationPrevious
                  onClick={() => {
                    if (params.page > 1)
                      setParams((prev: any) => ({
                        ...prev,
                        page: prev.page - 1,
                      }));
                  }}
                />
              </PaginationItem>
              <PaginationItem>
                <PaginationLink isActive={true}>{params.page}</PaginationLink>
              </PaginationItem>
              <PaginationItem>
                <PaginationNext
                  onClick={() => {
                    if (params.page < maxPages)
                      setParams((prev: any) => ({
                        ...prev,
                        page: prev.page + 1,
                      }));
                  }}
                />
              </PaginationItem>
            </PaginationContent>
          </Pagination>
        )}
      </div>
    );
  };

  return (
    <div className="flex flex-col md:flex-row w-full transition-all duration-700">
      {/* Search Patient */}
      <div className="md:w-[30%] self-start w-full">
        <div className="rounded-lg border bg-card shadow-sm">
          <SearchPatient />
        </div>

        {/* Create User Dialog */}
        <Dialog open={isCreateDialogOpen} onOpenChange={setIsCreateDialogOpen}>
          <DialogTrigger asChild>
            <Button className="w-full mt-2">Criar Utente</Button>
          </DialogTrigger>
          <DialogContent className="w-full">
            {/* <CreateCC setIsDialogOpen={setIsCreateDialogOpen} /> */}
          </DialogContent>
        </Dialog>
      </div>

      {/* Informed Consent Stats and Table */}
      <div className="flex flex-col md:pl-4 md:w-[70%] w-full">
        {ConsentStats()}
        {ConsentTable()}
        {maxPages > 1 && <TablePagination />}
      </div>
    </div>
  );
};

export default People;
