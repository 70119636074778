// Convert a date string to a formatted date string
export function formatDate(dateString: string | undefined): string {
  if (!dateString) return "";
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based in JavaScript
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  return `${day}/${month}/${year} ${hours}:${minutes}`;
}

export function formatDateProfile(dateString: string | undefined): string {
  if (!dateString) return "";
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based in JavaScript
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
}

export function calculateAge(birthDateString: string): number | undefined {
  if (!birthDateString) return;
  const [day, month, year] = birthDateString.split("-").map(Number);
  const birthDate = new Date(year, month - 1, day);
  const today = new Date();
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();
  if (
    monthDiff < 0 ||
    (monthDiff === 0 && today.getDate() < birthDate.getDate())
  ) {
    age--;
  }
  return age;
}

// Transform a date string to dd-mm-yyyy format
export function transformDateString(
  dateString: string | undefined
): string | boolean {
  if (!dateString) return false;
  const regexHyphen = /^(\d{2})-(\d{2})-(\d{4})$/;
  const regexSlash = /^(\d{2})\/(\d{2})\/(\d{4})$/;
  const regexDot = /^(\d{2})\.(\d{2})\.(\d{4})$/;
  const regexSpace = /^(\d{2}) (\d{2}) (\d{4})$/;
  const regexCompact = /^(\d{2})(\d{2})(\d{4})$/;

  let match;
  if (
    (match = dateString.match(regexHyphen)) ||
    (match = dateString.match(regexSlash)) ||
    (match = dateString.match(regexDot)) ||
    (match = dateString.match(regexSpace)) ||
    (match = dateString.match(regexCompact))
  ) {
    const [, day, month, year] = match;
    return `${day}-${month}-${year}`;
  }

  return false;
}
