import React, { useContext, useEffect, useRef, useState } from "react";
import { HpContext } from "../services/hp";
import { useNavigate } from "react-router-dom";

// Components
// import CreateCC from "../pages/CC/create";
// import UpdateCC from "../pages/CC/update";

// UI
import { Button } from "./ui/button";
import { Drawer, DrawerContent, DrawerTrigger } from "../components/ui/drawer";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { Avatar, AvatarFallback, AvatarImage } from "./ui/avatar";
import showToast from "./Toast";
import { Dialog, DialogContent, DialogTrigger } from "./ui/dialog";

// Icons
import { IoMdHelp } from "react-icons/io";
import { Moon, Settings, Sun, Users } from "lucide-react";
import { FaRegUser } from "react-icons/fa";
import { Separator } from "./ui/separator";

import { useMediaQuery } from "react-responsive";
import { useNavigateIfPathDiffers } from "../utils/navigateIfDiffers";
import HealthProfessional from "./HealthProfessional";

interface NavBarInterface {
  isLoading: boolean;
}

const links = [
  {
    label: "Utentes",
    link: "/people",
    icon: <FaRegUser className="text-black" size={18} />,
  },
  {
    label: "Perfil",
    link: "/settings?tab=0",
    icon: <FaRegUser className="text-black" size={18} />,
  },
  {
    label: "Afiliações",
    link: "/settings?tab=1",
    icon: <Users className="text-black" size={18} />,
  },
  {
    label: "Definições",
    link: "/settings?tab=2",
    icon: <Settings className="text-black" size={18} />,
  },
  {
    label: "Ajuda",
    link: "/settings?tab=4",
    icon: <IoMdHelp className="text-black" size={18} />,
  },
];

const useBreakpoint = () => {
  const [screen, setScreen] = useState("base");

  useEffect(() => {
    const updateScreenSize = () => {
      if (window.matchMedia("(min-width: 1280px)").matches) {
        setScreen("xl");
      } else if (window.matchMedia("(min-width: 768px)").matches) {
        setScreen("md");
      } else {
        setScreen("base");
      }
    };

    // Set the initial value
    updateScreenSize();

    // Add event listener
    window.addEventListener("resize", updateScreenSize);

    // Clean up event listener on unmount
    return () => window.removeEventListener("resize", updateScreenSize);
  }, []);

  return screen;
};

const Navbar = ({ isLoading }: NavBarInterface) => {
  const [isDark, setIsDark] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 767 });

  // Context
  const hpContext = useContext(HpContext);

  // Hooks
  const navigateIfPathDiffers = useNavigateIfPathDiffers();

  // State variables
  const sidebarRef = useRef<HTMLDivElement>(null);

  // Toggle
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [isUpdateDialogOpen, setIsUpdateDialogOpen] = useState(false);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target as Node)
      ) {
        setIsSidebarOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setIsSidebarOpen]);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setIsSidebarOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setIsSidebarOpen]);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const screenSize = useBreakpoint();

  useEffect(() => {
    if (screenSize === "md" || screenSize === "xl") {
      setIsSidebarOpen(false);
    }
  }, [screenSize]);

  return (
    <>
      <nav
        className={`w-screen fixed justify-center z-[1] ${
          isLoading ? "hidden" : "flex"
        } `}
      >
        <div
          className={`flex justify-between w-full max-w-screen-2xl mx-auto transition-all duration-700 bg-white px-4 py-6 z-50`}
        >
          {/* If we dont like the animation: "flex justify-between w-full md:px-6 bg-white px-4 py-4 z-20" */}
          <div className="flex items-center hover:cursor-pointer">
            <a
              className="leading-none"
              onClick={() => {
                navigateIfPathDiffers("/people");
              }}
            >
              <div
                className="flex items-center hover:scale-105 transition-all"
                onClick={() => {
                  if (isSidebarOpen) {
                    toggleSidebar();
                  }
                }}
              >
                <img
                  src={
                    isMobile
                      ? require("./images/humanos-logo-black.png")
                      : require("./images/humanos_logo_text_black.png")
                  }
                  alt="Humanos Logo"
                  className="md:h-7 h-10 w-auto"
                />
              </div>
            </a>
          </div>

          <div className="flex items-center justify-center space-x-2">
            <div className="items-center justify-center space-x-6 flex">
              <div className="flex items-center">
                <Button
                  variant="outline"
                  size="icon"
                  className="scale-90 md:flex hidden"
                  onClick={() => {
                    showToast("Dark mode em desenvolvimento.", "info", 3000);
                  }}
                >
                  <Sun
                    className={`h-[1.2rem] w-[1.2rem] transition-all duration-300 ${
                      isDark ? "-rotate-90 scale-0" : "rotate-0 scale-100"
                    }`}
                  />
                  <Moon
                    className={`absolute h-[1.2rem] w-[1.2rem] transition-all duration-300 ${
                      isDark ? "rotate-0 scale-100" : "rotate-90 scale-0"
                    }`}
                  />
                </Button>
                {hpContext?.hp ? (
                  <Drawer>
                    <DrawerTrigger asChild>
                      <div className="relative inline-block">
                        <div
                          className={`h-9 w-9 -z-0 ml-3 transition-all ${
                            isDropdownOpen ? "scale-105" : "hover:scale-105"
                          } hover:cursor-pointer`}
                        >
                          <Avatar>
                            <AvatarImage />
                            <AvatarFallback className="bg-gradient-to-br from-teal-400 to-cyan-700 text-white">
                              <FaRegUser size={18} />
                            </AvatarFallback>
                          </Avatar>
                        </div>
                      </div>
                    </DrawerTrigger>
                    <DrawerContent>
                      <HealthProfessional />
                    </DrawerContent>
                  </Drawer>
                ) : (
                  <Avatar>
                    <AvatarImage />
                    <AvatarFallback className="bg-gradient-to-br from-teal-400 to-cyan-700 text-white">
                      <FaRegUser size={18} />
                    </AvatarFallback>
                  </Avatar>
                )}
              </div>
              <Dialog
                open={isCreateDialogOpen}
                onOpenChange={setIsCreateDialogOpen}
              >
                <DialogTrigger asChild>
                  <span className="hidden"></span>
                </DialogTrigger>
                <DialogContent className="w-full">
                  {/*<CreateCC setIsDialogOpen={setIsCreateDialogOpen} />*/}
                </DialogContent>
              </Dialog>

              <Dialog
                open={isUpdateDialogOpen}
                onOpenChange={setIsUpdateDialogOpen}
              >
                <DialogTrigger asChild>
                  <span className="hidden"></span>
                </DialogTrigger>
                <DialogContent className="w-full">
                  {/*<UpdateCC setIsDialogOpen={setIsUpdateDialogOpen} />*/}
                </DialogContent>
              </Dialog>
            </div>

            <Button
              variant="outline"
              size="icon"
              className=" bg-white border-none hover:bg-white flex md:hidden"
              onClick={() => {
                toggleSidebar();
              }}
            >
              <MdKeyboardArrowDown
                className={`h-[2rem] w-[2rem] transition-all duration-300 ${
                  isSidebarOpen ? "-rotate-90 scale-0 " : "rotate-0 scale-100"
                }`}
              />
              <MdKeyboardArrowUp
                className={`absolute h-[2rem] w-[2rem] transition-all duration-300 ${
                  isSidebarOpen
                    ? "rotate-0 scale-100 bg-red-400 rounded-sm text-red-800 shadow-lg"
                    : "rotate-90 scale-0"
                }`}
              />
            </Button>
          </div>
        </div>

        <div
          className={`fixed top-[4.5rem] z-40 left-0 h-screen w-full transition-transform md:duration-700 duration-300 transform ${
            isSidebarOpen ? "translate-y-0" : "-translate-y-full"
          } bg-white text-white flex items-start justify-start`}
        >
          <div className="flex w-screen justify-start flex-col h-screen items-center px-6 pt-6 pb-24">
            {links.map((item, index) => (
              <div
                key={index}
                className="flex justify-between items-center w-full px-2 hover:bg-neutral-50 transition-all rounded-md hover:cursor-pointer py-3 text-neutral-600"
                onClick={() => {
                  navigateIfPathDiffers(`${item.link}`);
                  if (isSidebarOpen) {
                    toggleSidebar();
                  }
                }}
              >
                <a key={index} className=" text-lg font-light tracking-wide">
                  {item.label}
                </a>
                {item.icon}
              </div>
            ))}
            <div
              className="flex justify-between items-center w-full px-2 hover:bg-neutral-50 transition-all rounded-md hover:cursor-pointer py-3 text-neutral-600"
              onClick={() => {
                showToast("Dark mode em desenvolvimento.", "info", 3000);
              }}
            >
              <p className="text-lg font-light tracking-wide">Theme</p>
              <Button variant="outline" size="icon" className="scale-90 ">
                <Sun
                  className={`h-[1.2rem] w-[1.2rem] transition-all duration-300 ${
                    isDark ? "-rotate-90 scale-0" : "rotate-0 scale-100"
                  }`}
                />
                <Moon
                  className={`absolute h-[1.2rem] w-[1.2rem] transition-all duration-300 ${
                    isDark ? "rotate-0 scale-100" : "rotate-90 scale-0"
                  }`}
                />
              </Button>
            </div>
            <Separator className="my-2" />
            <div className="flex flex-col w-full pt-2">
              <Button
                className="text-center text-black hover:bg-neutral-50"
                variant="outline"
                onClick={() => hpContext?.logout()}
              >
                Terminar Sessão
              </Button>
            </div>
          </div>
        </div>
      </nav>
      {/*isLoading && <Loading />*/}
    </>
  );
};

export default Navbar;
